import * as inflationAdjustment from './inflationAdjustment'
import * as objectOwner from './objectOwner'
import * as projectFiles from './projectFiles'
import * as projectStatus from './projectStatus'
import * as stepBranches from './stepBranches'
import { append, map, pipe, prop, reduce } from 'rambda'
import getDefaultCalculatorVariables from './utils/getDefaultCalculatorVariables'
import { mergeDeepRight } from 'views/utils/objects'
import projectFactory from './projectFactory'
import { stateOptions } from '@store/utils'

const storeMain = {
  getters: {
    projectFactory,
    projectOptions: stateOptions(),

    mainProject: (_, { queryOne }) => {
      const project = queryOne('project', { isMain: true })

      return {
        name: 'Saulės Parkas Nr.1',
        status: 'ACTIVE',
        termDays: null,
        price: 950,
        maintenanceAnnual: 19,

        locationFull: null,
        plotNumber: null,
        location: null,
        posX: 58,
        posY: 44,

        capacity: 250,
        capacityUsed: 117,

        ...getDefaultCalculatorVariables(),
        ...(project || {}),
      }
    },
  },
}

const substores = [
  inflationAdjustment,
  objectOwner,
  projectFiles,
  projectStatus,
  stepBranches,
]

const store = pipe(
  map(prop('store')),
  append(storeMain),
  reduce(mergeDeepRight, {}),
)(substores)

export default {
  auto: '*',
  store,
}
