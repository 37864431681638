export const store = {
  actions: {
    LOAD_PROJECT_FILES: async ({ dispatch, getters: { query } }, projectId) => {
      await dispatch('FETCH_PROJECT_DOCUMENT', { projectId })

      const fileIds = query('projectDocument')
        .map(({ fileId }) => fileId)

      if (!fileIds.length) return

      return dispatch('FETCH_FILE', fileIds)
    },
  },
}
