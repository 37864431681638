<template>
  <div class="Card">
    <div v-if="title" class="title">
      {{ title }}
    </div>
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    title: String,
  },
}
</script>

<style lang="scss">
.Card {
  @include trans;
  background: $white;
  // border: 1px solid $border-color-light;
  border-radius: $radius 0;
  box-shadow: $shadow-md;
  margin-bottom: 1rem;
  overflow: hidden;
  padding: 2rem;
  position: relative;

  > .title {
    font-size: $h4;
    font-weight: $semibold;
    padding: 1rem 1rem 0;
  }

  .data-row {
    line-height: 2rem;
  }

  .Empty {
    margin: 0;
  }

  &:hover {
    box-shadow: $shadow-lg;
  }
}
</style>
