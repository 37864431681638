<template>
  <a
    :href="`tel:${number}`"
    @click="call"
    @keydown="call">
    {{ number }}
  </a>
</template>

<script>
import Track from '../../mixins/Track'

export default {
  mixins: [Track],
  props: {
    number: [Number, String],
  },
  methods: {
    call() {
      this.track('call', this.number.replace(/ /g, ''))
    },
  },
}
</script>
