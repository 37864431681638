<template>
  <Route :id="reservation.id" class="ProjectReservation" to="Reservation">
    <Row>
      <Column :xs="6">
        <div class="left-side">
          {{ reservation.capacity | kw }} užsakymas
        </div>
      </Column>
      <Column :xs="6">
        <div class="right-side">
          <Tag>{{ status }}</Tag>
          <Tag v-if="paymentUnpaid" class="danger">
            Neapmokėtas
          </Tag>
          <!-- <Icon name="right" /> -->
        </div>
      </Column>
    </Row>
  </Route>
</template>

<script>
import { getters } from '../utils'

export default {
  props: {
    reservation: Object,
  },
  computed: {
    paymentUnpaid() {
      return this.getReservationUnpaidPayment(this.reservation)
    },
    status() {
      return this.getReservationStatus(this.reservation)
    },
    ...getters('getReservationUnpaidPayment', 'getReservationStatus'),
  },
}
</script>

<style lang="scss">
$_main-lighter: lighten($main-light, 2%);

.ProjectReservation {
  @include trans();
  border: 1px solid mix($main, $main-light, 20);
  border-radius: $radius;
  color: $text-color;
  display: block;
  font-size: $h4;
  font-weight: $semibold;
  margin-bottom: 1rem;

  .left-side {
    padding: 1.5rem;
  }

  .right-side {
    float: right;
    padding: 1.5rem 1rem;

    > .Icon, > .Tag {
      line-height: $lh;
      margin-left: 0.5rem;
      vertical-align: middle;
    }

    > .Icon {
      font-size: $h2;
    }
  }

  &:hover {
    background: $_main-lighter;
  }
}
</style>
