<template>
  <transition appear name="fade">
    <ModalCont>
      <div v-if="modal.show" class="Modal">
        <h3 v-if="modal.name" class="name">
          {{ modal.name }}
        </h3>
        <div class="text" :class="{ 'with-icon': modal.icon }">
          <Icon v-if="modal.icon" :name="modal.icon" />
          {{ modal.text }}
          <div v-if="modal.typeConfirm" class="type-confirm">
            <Field
              :label="`Jeigu tikrai norite tęsti, įrašykite '${modal.typeConfirm}':`"
              :placeholder="modal.typeConfirm"
              type="text"
              :value.sync="typeConfirm" />
          </div>
        </div>
        <div class="actionsRow">
          <Btn
            v-for="action in modal.actions"
            :key="action.label"
            class="ib"
            :class="action.theme"
            :data-cy="`actionType:${action.type}`"
            :disabled="isActionDisabled(action)"
            @click="process(action)">
            {{ action.label }}
          </Btn>
        </div>
      </div>
    </ModalCont>
  </transition>
</template>

<script>
import { actions, getters } from 'views/utils'

export default {
  data() {
    return {
      typeConfirm: null,
    }
  },
  computed: getters('modal'),
  methods: {
    async process({ action, type }) {
      if (typeof action === 'function') {
        await action(this)
      } else if (typeof this[action] !== 'undefined') {
        await this[action]()
      }

      if (type === 'confirm') this.close()
    },
    close() {
      this.CLOSE_MODAL()
    },
    confirm() {
      this.modal.confirm()
    },
    isActionDisabled(action) {
      return action.type === 'confirm' &&
        (this.modal.typeConfirm && this.modal.typeConfirm !== this.typeConfirm)
    },
    ...actions('CLOSE_MODAL'),
  },
}
</script>

<style lang="scss">
.Modal {
  @include trans;
  background: $white;
  border-radius: $radius-lg;
  box-shadow: 0 4px 26px rgba(0, 0, 0, 0.3);
  // max-width: 480px;
  max-height: 100%;
  min-width: 320px;
  overflow-x: hidden;
  overflow-y: auto;
  pointer-events: auto;
  width: 100%;
  z-index: 13;
  @include lg {
    max-width: 990px;
  }

  > .overlay {
    z-index: 4;
  }

  > .name {
    padding: 1.5rem 0;
    text-align: center;
  }

  > .text {
    font-size: $h4;
    font-weight: $medium;
    padding: 2rem 2rem 1rem;
    position: relative;

    > .Icon {
      font-size: $h1;
      left: 2rem;
      position: absolute;
      top: 1rem;
    }

    > .type-confirm {
      margin-top: 1rem;
      text-align: left;

      .Field-cont {
        margin: 0;
      }
    }

    &.with-icon {
      padding-left: 6rem;
    }
  }

  > .actionsRow {
    display: flex;
    flex-direction: column;
    gap: $grid-unit;
    padding: 1.5rem;
    @include md {
      flex-direction: row;
    }

    > .Btn {
      flex-grow: 1;
    }
  }
}
</style>
