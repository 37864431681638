import { castArray } from 'views/utils'
import { parseObject } from '@/store/utils'

export default function parseObjects(ctx) {
  if (!ctx.result) return

  castArray((ctx.result.data && Array.isArray(ctx.result.data))
    ? ctx.result.data
    : ctx.result).forEach(parseObject)
}
