import { webOrigin } from '../../config'

const defaultTitle = 'Saulės bendruomenė'
const defaultDescription = 'Prie individualių poreikių prisitaikanti saulės elektros energija, prieinama kiekvienam Lietuvos gyventojui. Saulės elektrinės statyba, naujo skaitliuko įrengimas kliento namuose ir nuolatinė priežiūra. Aplinkai draugiškos saulės jėgainės - alternatyvus sprendimas sumažinti elektros sąskaitas.'
const metaWithCloneOg = ['description']

const separator = '|'

function formTitle(title) {
  if (typeof title === 'string') {
    title = {
      inner: title,
      separator,
      complement: defaultTitle,
    }
  }

  return `${title.inner} ${title.separator} ${title.complement}`
}

export default (title, description, image) => ({
  head: {
    title() {
      return {
        inner: this.metaTitle,
      }
    },
    meta() {
      return [
        { id: '_description', name: 'description', content: this.metaDescription },
        { id: '_ogdescription', name: 'og:description', content: this.metaDescription },
        ...(image
          ? [{
            id: '_ogimage',
            name: 'og:image',
            content: this.metaImage,
          }]
          : []),
      ]
    },
  },
  computed: {
    metaTitle() {
      const metaTitle = this.$_parseMeta(title) || defaultTitle

      return formTitle(metaTitle)
    },
    metaDescription() {
      const metaDescription = this.$_parseMeta(description) || defaultDescription

      if (metaDescription.length <= 160) {
        return metaDescription
      }

      return `${metaDescription
        .replace(/\n/g, ' ')
        .slice(0, 160)
        .split(' ')
        .slice(0, -1)
        .join(' ')}...`
    },
    metaImage() {
      const imageUrl = this.$_parseMeta(image)

      if (!imageUrl) return

      return `${webOrigin}${imageUrl.slice(1)}`
    },
  },
  methods: {
    $_parseMeta(metaTag) {
      if (typeof metaTag === 'undefined') return

      return typeof metaTag === 'function'
        ? metaTag(this)
        : metaTag
    },
    $_setMetaKey(name, content, id) {
      if (!content) return
      if (!id) id = `_${name.replace(/\W+/g, '')}`

      if (metaWithCloneOg.includes(name)) {
        this.$_setMetaKey(
          `og:${name}`,
          content,
          id.replace('_', '_og'),
        )
      }
    },
  },
  watch: {
    metaTitle: {
      immediate: true,
      handler(metaTitle) {
        const isTitleSeparated = metaTitle.includes(separator)

        if (!isTitleSeparated) {
          this.title = metaTitle
        }

        this.$_setMetaKey('og:title', metaTitle)
        this.$emit('updateHead')

        this.$nextTick(() => {
          if (isTitleSeparated) {
            window.document.title = metaTitle.trim()
          }
        })
      },
    },
    metaDescription(metaDescription) {
      this.$_setMetaKey('description', metaDescription)
      this.$emit('updateHead')
    },
    metaImage(metaImage) {
      this.$_setMetaKey('og:image', metaImage)
      this.$emit('updateHead')
    },
  },
  render: () => '',
})
