import { merge } from 'views/utils'

const modules = Object
  .fromEntries(require
    .context('./', true, /^\.\/\w+$/)
    .keys()
    .map(path => path.slice(2))
    .filter(path => path !== 'index')
    .map(moduleName => [moduleName, require(`./${moduleName}`).default]))

export default Object.keys(modules).map((moduleKey) => {
  const storeModule = modules[moduleKey]

  if (!storeModule.auto) storeModule.auto = []

  const auto = new Set(storeModule.auto === '*'
    ? ['actions', 'getters', 'mutations', 'sync']
    : storeModule.auto)

  return Object.assign(
    merge({
      // external service name without apiBase (someService)
      serviceName: auto.has('actions')
        ? moduleKey
        : null,

      hasJoins: (storeModule.joins && storeModule.joins.length) || false,
      joins: storeModule.joins || [],

      isClearable: auto.has('mutations') && storeModule.isClearable !== false,
      isFrozen: false,

      cacheFor: 4000,

      // suffix for store actions (SOME_SERVICE)
      storeSuffix: moduleKey.split(/(?=[A-Z])/).join('_').toUpperCase(),
      // internal store key (someService) used for queries
      storeKey: moduleKey,
      joinedStateGetterKey: `${storeModule.storeKey || moduleKey}Joined`,

      store: {
        state: {
          list: {},
          pages: {},
        },
      },
    }, storeModule),
    { auto })
})
