import { factory } from '@/store/utils'

// const PAYSERA = 'PAYSERA'

export default {
  auto: '*',

  store: {
    state: {
      // paymentAccountTypes: [
      //   {
      //     label: 'Paysera',
      //     value: PAYSERA,
      //   },
      // ],
    },

    getters: {
      paymentAccountFactory: factory(() => ({
        label: null,
        // type: PAYSERA,
        originProjectId: null,
        password: null,

        iban: null,
        country: null,
        countrySubdivision: null,
        town: null,
        street: null,
        building: null,
        postcode: null,
        bankName: null,
        name: null,
        bic: null,

        bankDetails: null,
      })),
    },
  },
}
