import { factory } from '../utils'

export default {
  auto: '*',

  store: {
    getters: {
      snippetFactory: factory(() => ({
        key: null,
        value: null,
      })),
      getAllSnippetsKeys: (_, { query }) => query('snippet')
        .map(snippet => snippet.key),

      getSnippetValue: (_, { query }) => key => (query('snippet', { key }, true) || {}).value,
      snippetsValues: (_, { getSnippetValue, getAllSnippetsKeys }) => Object
        .fromEntries(getAllSnippetsKeys
          .map(key => [key, getSnippetValue(key)])),

      injectSnippets: (_, { snippetsValues }) => text => Object
        .entries(snippetsValues)
        .reduce((currentText, [snippetKey, snippetValue]) => currentText
          .replace(`{${snippetKey}}`, snippetValue), text),
    },
  },
}
