import Vue from 'vue'
import { assocPath } from 'rambda'

// if (recaptchaKey) {
//   Vue.use(VueReCaptcha, {
//     siteKey: recaptchaKey,
//     loaderOptions: {
//       autoHideBadge: true,
//     },
//   })
//   Vue.prototype.$recaptchaToken = async function(actionName = 'unnamed') {
//     try {
//       await this.$recaptchaLoaded()
//       return this.$recaptcha(actionName)
//     } catch (error) {
//       return 'failed'
//     }
//   }
// } else {
Vue.prototype.$recaptchaToken = async function() {
  return 'none'
}
// }

Vue.prototype.$wrap = async function(action, options, _successMessage) {
  if (typeof options !== 'object') {
    options = { isLoading: options }
    if (_successMessage) options.successMessage = _successMessage
  }

  const { isLoading, successMessage } = options

  try {
    if (isLoading) this[isLoading] = true

    const result = await (typeof action === 'function' ? action() : action)

    if (successMessage) {
      this.$store.dispatch('SUCCESS', successMessage)
    }

    return result
  } catch (error) {
    if (this.setErrorMessage) {
      return this.setErrorMessage(error)
    }

    throw error
  } finally {
    if (isLoading) this[isLoading] = false
  }
}

export const $up = function $up(...upArguments) {
  const [path, value] = upArguments

  // TODO: move out binding logic to a new function curryBound
  if (upArguments.length === 1) {
    return function(value) {
      return $up.bind(this)(path, value)
    }
  }

  const [objectKey, ...propertyPath] = path.split('.')

  if (!propertyPath.length) {
    return this.$emit(`update:${objectKey}`, value)
  }

  // join by '.' so index values are converted to Number rambda-side
  this.$emit(
    `update:${objectKey}`,
    assocPath(propertyPath.join('.'), value, this[objectKey]),
  )
}

Vue.prototype.$up = $up
