import { castArray } from 'views/utils'

const loadQueue = []
const postLoad = []
let loadingPromise = null

export default {
  store: {
    state: {
      isLoaded: true,
    },
    actions: {
      EXEC_POST_LOAD() {
        postLoad.forEach((task) => {
          if (task.done) return
          task.done = true
          task.fn()
        })
      },

      /*
        Sets state.isLoaded = true, after given loader Promise
        is resolved.
      */
      LOAD({ commit, dispatch, state }, loader) {
        if (!loader) return

        const loaderFunction = () => Promise
          .all(castArray(loader)
            .map(actionNameOrFunction =>
              typeof actionNameOrFunction === 'function'
                ? actionNameOrFunction()
                : dispatch(actionNameOrFunction)))

        loadQueue.push(loaderFunction)

        const executeNext = async () => {
          if (!loadQueue.length) {
            commit('SET_LOADED', true)
            dispatch('EXEC_POST_LOAD')
            return Promise.resolve()
          }

          const task = loadQueue.slice(0, 1)[0]
          await task()
          if (loadQueue.length) loadQueue.splice(0, 1)
          loadingPromise = executeNext()
        }

        if (!loadQueue.length) return Promise.resolve()

        if (state.isLoaded) {
          commit('SET_LOADED', false)
          return executeNext()
        }

        return loadingPromise
      },

      /*
        Triggers given function fn when page has been loaded.
      */
      POST_LOAD({ commit, state }, function_) {
        if (state.isLoaded) return function_()

        commit('ADD_TO_POSTLOAD', function_)
      },
    },

    mutations: {
      ADD_TO_POSTLOAD(_, function_) {
        postLoad.push({
          done: false,
          fn: function_,
        })
      },
      SET_LOADED(state, value) {
        state.isLoaded = value
      },
    },

    getters: {
      isLoaded: state => state.isLoaded,
    },
  },
}
