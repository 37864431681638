import { factory } from '../utils'

export default {
  auto: '*',

  store: {
    getters: {
      solarModuleFactory: factory(() => ({
        manufacturer: null,
        model: null,
        capacity: null,
        warranty: null,
        points: null,
        description: null,
        moreInfoSrc: null,
      })),
    },
  },
}
