<template>
  <div class="HorizontalScroll">
    <div class="controlsContainer">
      <div v-if="hasControls" class="controls">
        <Btn class="right arrow no-text" @click="scroll" />
        <Btn class="left arrow no-text" @click="scroll('left')" />
      </div>
    </div>
    <!-- <div v-else class="emptySide" /> -->
    <div
      class="itemsContainer"
      :class="{
        dragging: isClickDisabled,
      }">
      <div
        ref="items"
        class="items"
        @blur="endDrag"
        @dragend="endDrag"
        @mousedown="startDrag"
        @mouseleave="endDrag"
        @mousemove="drag"
        @mouseup="endDrag">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
import { getters } from 'views/utils'

export default {
  props: {
    itemsCount: Number,
  },
  data() {
    return {
      dragStart: 0,
      dragAmount: 0,
      isDragging: false,
      isClickDisabled: false,
      scrolled: 0,
      scrollMax: 0,
    }
  },
  computed: {
    hasControls() {
      return this.screenSizes.includes('lg')
    },
    ...getters('screenSizes'),
  },
  methods: {
    startDrag(event) {
      this.isDragging = true
      this.dragStart = event.pageX - this.$refs.items.offsetLeft
      this.dragAmount = this.$refs.items.scrollLeft
    },
    drag(event) {
      if (!this.isDragging) return

      event.preventDefault()

      const dragPosition = event.pageX - this.$refs.items.offsetLeft
      const dragChange = (dragPosition - this.dragStart)

      if (!this.isClickDisabled && Math.abs(dragChange) > 10) {
        this.isClickDisabled = true
      }

      this.$refs.items.scrollLeft = this.dragAmount - dragChange
    },
    endDrag(event) {
      this.isDragging = false
      this.isClickDisabled = false
      event.preventDefault()
    },
    scroll(position = 'right') {
      const gapPx = 15
      const sectionWidth = this.$refs.items.scrollWidth
      const elementWidth = (sectionWidth - (gapPx * (this.itemsCount))) / this.itemsCount
      const scrollPx = position === 'right'
        ? elementWidth + gapPx
        : (elementWidth + gapPx) * -1

      this.scrollTo(this.$refs.items, scrollPx, 200)
    },
    scrollTo(element, scrollPixels, duration) {
      const scrollPos = element.scrollLeft
      // Condition to check if scrolling is required
      if (!((scrollPos === 0 || scrollPixels > 0) && (element.clientWidth + scrollPos === element.scrollWidth || scrollPixels < 0))) {
        // Get the start timestamp
        const startTime =
          'now' in window.performance
            ? performance.now()
            : new Date().getTime()

        // eslint-disable-next-line no-inner-declarations
        function scroll(timestamp) {
          // Calculate the timeelapsed
          const timeElapsed = timestamp - startTime
          // Calculate progress
          const progress = Math.min(timeElapsed / duration, 1)
          // Set the scrolleft
          element.scrollLeft = scrollPos + scrollPixels * progress
          // Check if elapsed time is less then duration then call the requestAnimation, otherwise exit
          if (timeElapsed < duration) {
            // Request for animation
            window.requestAnimationFrame(scroll)
          } else {

          }
        }
        // Call requestAnimationFrame on scroll function first time
        window.requestAnimationFrame(scroll)
      }
    },
  },
}
</script>

<style lang="scss">
.HorizontalScroll {
  display: flex;

  // .routerLink {
  //   border-bottom: none;
  // }

  .controlsContainer {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    width: 5vw;
    @include lg {
      width: 15vw;
    }

    .controls {
      margin-right: 2rem;

      .Btn {
        justify-content: flex-end;
        width: unset;

        .circle {
          margin-left: -1rem;
        }

        &.right {
          border-bottom: none;
          border-radius: $radius-round $radius-round 0 0;
        }

        &.left {
          border-radius: 0 0 $radius-round $radius-round;

          &::after {
            transform: rotate(180deg);
          }
        }
      }
    }
  }

  .itemsContainer {
    width: 94vw;
    @include lg {
      width: 84vw;
    }

    &.dragging {
      cursor: grabbing;

      a {
        pointer-events: none;
      }
    }

  }

  .items {
    display: flex;
    gap: 15px;
    // justify-content: space-between;
    // overflow-x: hidden;
    overflow-y: hidden;
    user-select: none;
    white-space: nowrap;
    width: 100%;
    @include lg {
      overflow-x: hidden;
    }
  }
}
</style>
