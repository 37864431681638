<template>
  <!-- eslint-disable-next-line vue-a11y/label-has-for -->
  <label v-bind="$attrs" class="FieldLabel">
    <slot>
      <Icon v-if="icon" :name="icon" />
      {{ label }}
      <span v-if="required" class="required">
        *
      </span>
    </slot>
  </label>
</template>

<script>
export default {
  props: {
    icon: String,
    label: String,
    required: Boolean,
  },
}
</script>

<style lang="scss">
.FieldLabel {
  @include trans;
  color: inherit;
  display: block;
  font-size: $h5;
  font-weight: $semibold;
  line-height: 2.5rem;
  position: relative;
  @include md {
    font-size: inherit;
  }

  > .required {
    color: $danger;
  }
}
</style>
