<template functional>
  <div :class="['Headline', data.class, data.staticClass]">
    <slot />
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss">
.Headline {
  color: $white;
  font-weight: $regular;
  margin-bottom: 1rem;
  @include md {
    margin-bottom: 2rem;
  }

  &::before {
    background: $blue;
    border-radius: 60px;
    content: ' ';
    display: inline-block;
    font-size: 2rem;
    height: 0.5rem;
    margin-right: 0.5rem;
    padding: $h1;
    position: absolute;
    width: 1rem;
  }

  &.white {
    color: $white;
  }
}
</style>
