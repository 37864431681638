var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.project)?_c('Sect',{staticClass:"Calculator",attrs:{"id":"Calculator"}},[_c('Wrap',{staticClass:"lg"},[_c('h2',[_vm._v(_vm._s(_vm.titleToDisplay))]),_c('Row',{staticClass:"content"},[_c('Column',{staticClass:"sliderSide",attrs:{"lg":8}},[_c('div',{staticClass:"sub"},[_vm._l((_vm.paragraphsToDisplay),function(paragraph,index){return _c('p',{key:index},[_vm._v(" "+_vm._s(paragraph)+" ")])}),_c('small',[_vm._v(" "+_vm._s(_vm.subTextToDisplay)+" ")])],2),(!_vm.disabled)?_c('Slider',{attrs:{"format":_vm.tooltipFormat,"max":_vm.maxAmount,"min":_vm.minAmount,"processStyle":{
            backgroundColor: '#4b6ce6',
          },"railStyle":{
            height: '10px',
          },"tooltipPosition":_vm.tooltipPosition,"tooltipStyle":{
            background: '#4b6ce6',
            borderColor: '#4b6ce6',
            borderRadius: '60px',
            cursor: 'pointer',
            padding: '2rem 1.5rem',
            margin: '0 0 2rem 0',
            textAlign: 'center',
            fontSize: '1.2rem',
            fontWeight: 'bold',
            whiteSpace: 'pre',
          },"value":_vm.value},on:{"update:value":function($event){return _vm.$emit('update:value', $event)}}}):_vm._e(),(_vm.isDesktop)?_c('div',{staticClass:"disclaimer"},[_c('p',{domProps:{"innerHTML":_vm._s(_vm.disclaimersToDisplay)}})]):_vm._e()],1),_c('Column',{attrs:{"lg":4}},[_c('div',{staticClass:"calculations"},[_vm._l((_vm.calculatedWithLabels),function(calculation,index){return _c('div',{key:index},[_c('div',{staticClass:"calculation"},[_c('div',{staticClass:"label"},[_vm._v(" "+_vm._s(calculation[0])+" ")]),_c('div',{staticClass:"value"},[_vm._v(" "+_vm._s(calculation[1])+" ")])])])}),(!_vm.isBusinessMode)?_c('Field',{attrs:{"disabled":_vm.isBusinessMode,"label":"Skaičiuoti su parama","type":"checkbox","value":_vm.countApva},on:{"update:value":function($event){_vm.countApva=$event}}}):_vm._e()],2)])],1),_vm._t("default"),(!_vm.isDesktop)?_c('div',{staticClass:"disclaimer"},[_c('p',{domProps:{"innerHTML":_vm._s(_vm.disclaimersToDisplay)}})]):_vm._e()],2)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }