var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"CapacitySlider"},[_c('div',{staticClass:"details"},[_c('div',{staticClass:"used"},[_c('div',{staticClass:"label"},[_vm._v(" Rezervuota: ")]),_vm._v(" "+_vm._s(_vm.used)+" kW ("+_vm._s(_vm._f("percent")(_vm.percent))+") ")]),_c('div',{staticClass:"total"},[_vm._v(" "+_vm._s(_vm.total)+" kW ")])]),_c('Slider',{attrs:{"disabled":true,"dotStyle":{
      display: 'none',
    },"interval":_vm.sliderInterval,"max":_vm.total,"processStyle":{
      backgroundColor: '#4b6ce6',
    },"railStyle":{
      background: 'transparent',
      height: '8px',
    },"tooltip":"none","value":_vm.used}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }