import Router from 'vue-router'
import { basePath } from 'config'
import routes from './routes'
import { scrollBehavior } from './smoothScroll'

export default new Router({
  base: basePath,
  mode: 'history',
  saveScrollPosition: true,
  scrollBehavior,
  routes,
})
