import { castArray } from '../../views/utils'
import routes from '../routes'

const appendAuth = ({ auth }) => (child) => {
  if (!auth) return child

  const leadingAuth = castArray(child.auth ? child.auth : auth)
  return { ...child, auth: leadingAuth }
}

// TODO: rewrite cleaner, possibly without reduce
const flatten = children =>
  children.reduce((array, route) => {
    array.push(route.auth
      ? Object.assign(route, { auth: castArray(route.auth) })
      : route)

    if (route.children) {
      array.push(...flatten(route.children.map(appendAuth(route))))
    }

    return array
  }, [])

export default flatten(routes)
  .filter(route => route.name)
  .reduce((accumulator, { name, auth }) => {
    accumulator[name] = auth || null
    return accumulator
  }, {})
