import { pluckIds } from '@/views/utils/collections'

export default {
  auto: '*',
  hasJoins: true,

  store: {
    getters: {
      getReservationUnpaidPayment: (_, { queryOne }) => ({ id }) => queryOne('payment', {
        acceptedAt: null,
        cancelledAt: null,
        paidAt: null,
        reservationId: id,
      }),

      getReservationStatus: () => reservation =>
        reservation.isCancelled ? 'Atšauktas' : 'Aktyvus',
    },

    actions: {
      async LOAD_RESERVATIONS_CHILDREN({ dispatch }, reservations) {
        if (!reservations.length) return

        const [reservationIds, projectIds, promoIds, userIds] = pluckIds([
          'id',
          'projectId',
          'promoId',
          'userId',
        ], reservations)

        await dispatch('LOAD', () => Promise.all([
          dispatch('FETCH_CONTRACT', { reservationId: { $in: reservationIds } }),
          dispatch('FETCH_PAYMENT', { reservationId: { $in: reservationIds } }),
          dispatch('FETCH_PROJECT', projectIds),
          dispatch('FETCH_PROMO', promoIds),
          dispatch('FETCH_USER', userIds),
          dispatch('FETCH_USER_STEP', { reservationId: { $in: reservationIds } }),
        ]))
      },
    },
  },
}
