<template>
  <div id="TopBar">
    <Wrap class="xxl">
      <div v-if="screenSizes.includes('lg')" class="navigation-contacts">
        <Icon name="phone" />
        <a href="tel:+370 633 82 277">
          +370 633 82 277
        </a>
        <Icon name="mail" />
        <a href="mailto:info@saulesbendruomene.lt">
          info@saulesbendruomene.lt
        </a>
      </div>
      <div v-if="isHome" class="logoAndSwitch" :class="{ inline: screenSizes.includes('xxl') }">
        <MainLogo />
        <label v-if="screenSizes.includes('lg')" class="switch">
          <input type="checkbox" @click="toggleCheckbox">
          <div class="slider" :class="{ checked: isBusinessMode }">
            <div class="option" :class="{ checked: !isBusinessMode }">
              <Icon name="person" />
              <span>privatiems</span>
            </div>
            <div class="option" :class="{ checked: isBusinessMode }">
              <Icon name="suitcase" />
              verslui
            </div>
          </div>
        </label>
      </div>
      <MainLogo v-else />
      <!-- <MainLogo />
      <label v-if="isHome && screenSizes.includes('xl')" class="switch">
        <input type="checkbox" @click="toggleCheckbox">
        <div class="slider" :class="{ checked: isBusinessMode }">
          <div class="option" :class="{ checked: !isBusinessMode }">
            <Icon name="person" />
            <span>privatiems</span>
          </div>
          <div class="option" :class="{ checked: isBusinessMode }">
            <Icon name="suitcase" />
            verslui
          </div>
        </div>
      </label> -->
      <div id="menu" aria-label="Navigacija" :class="{ show: showMenu }" role="navigation">
        <MenuLink
          v-for="(link, index) in menuDesktop"
          :key="index"
          :class="{ last: (index === menuDesktop.length - 1) }"
          :index="index"
          :link="link" />
        <AuthMenu />
      </div>
      <div id="mobile-menu-cont">
        <div
          ref="hamburger"
          id="hamburger"
          @click="showMenu = !showMenu"
          @keypress.enter="showMenu = !showMenu">
          <Icon :name="showMenu ? 'cross' : 'menu'" />
        </div>
        <transition name="slide-side">
          <div
            v-if="showMenu"
            ref="mobileMenu"
            id="mobile-menu"
            role="navigation"
            @scroll.prevent
            @touchmove.prevent
            @wheel.prevent>
            <div class="mobile-links-wrapper">
              <MenuLink
                v-for="(link, index) in menuMobile"
                :key="link.name"
                :class="{ last: (index === menuMobile.length - 1) }"
                :index="index"
                :link="link"
                @click="showMenu = false" />
            </div>
            <div class="bottom">
              <AuthMenu @click.native="showMenu = false" />
            </div>
          </div>
        </transition>
      </div>
    </Wrap>
  </div>
</template>

<script>
import { actions, getters } from '../../utils'
import AuthMenu from './AuthMenu'
import MainLogo from './MainLogo'
import MenuLink from './MenuLink'

export default {
  components: { AuthMenu, MainLogo, MenuLink },
  data() {
    return {
      // isBusiness: false,
      showMenu: false,
      menuDesktop: [
        {
          // to: { name: 'Index', hash: '#kaip-veikia' },
          to: { name: 'SolarPlants' },
          label: 'Saulės parkai',
        },
        {
          to: { name: 'ApvaIndividuals' },
          label: 'Apva parama',
        },
        {
          label: 'Apie mus',
          children: [
            { label: 'Apie', to: 'AboutUs' },
            { label: 'Kaip tai veikia', to: 'HowItWorks' },
            { label: 'Naujienos', to: 'News' },
            { label: 'Kontaktai', to: 'Contact' },
            { label: 'Žemės savininkams', to: 'https://suninvestmentgroup.com/lt/landing-landowners', isExternal: true },
          ],
        },
        {
          to: 'Faq',
          label: 'D.U.K',
        },
      ],
      menuMobile: [
        {
          // to: { name: 'Index', hash: '#kaip-veikia' },
          to: { name: 'SolarPlants' },
          label: 'Saulės parkai',
        },
        {
          to: { name: 'ApvaIndividuals' },
          label: 'Apva parama',
        },
        {
          label: 'Apie mus',
          children: [
            { label: 'Apie', to: 'AboutUs' },
            { label: 'Kaip tai veikia', to: 'HowItWorks' },
            { label: 'Naujienos', to: 'News' },
            { label: 'Kontaktai', to: 'Contact' },
            { label: 'Žemės savininkams', to: 'https://suninvestmentgroup.com/lt/landing-landowners', isExternal: true },
          ],
        },
        {
          to: 'Faq',
          label: 'D.U.K',
        },
        {
          to: { name: 'Contact' },
          label: 'Kontaktai',
        },
      ],
      clickHandler: '',
    }
  },
  computed: {
    isHome() {
      return this.$route.name === 'Index'
    },
    ...getters('isDesktop', 'screenSizes', 'isBusinessMode'),
  },
  methods: {
    toggleCheckbox() {
      this.SET_MODE(!this.isBusinessMode)
      // this.$emit('setCheckboxVal', this.isBusiness)
    },
    checkClickLocation(event) {
      if (!this.$refs.mobileMenu || !this.showMenu) return

      if (!['hamburger', 'mobileMenu'].some(ref => this.$refs[ref].contains(event.target))) {
        this.showMenu = false
      }
    },
    ...actions('SET_MODE'),
  },
  async beforeMount() {
    this.$offEvents = await this.ON({
      click: this.checkClickLocation,
    })
  },
  beforeDestroy() {
    this.$offEvents && this.$offEvents()
  },
}
</script>

<style lang="scss">
$_burger-width: 2.25rem;
$_burger-width-md: 3rem;

#TopBar {
  margin-bottom: 2rem;
  padding: 1rem 2rem 0;
  position: absolute;
  width: 100%;
  z-index: 7;
  @include lg {
    padding: 1rem 3rem;
  }

  .navigation-contacts {
    font-weight: $bold;
    text-align: right;

    > a {
      color: $white;
      margin-right: 1.5rem;

      &:hover {
        border-color: transparent;
        text-decoration: underline;
      }
    }

    > .Icon {
      color: $white;
      margin-right: 0.5rem;

    }
  }

  .logoAndSwitch {
    display: inline-block;

    .switch {
      margin: 0;
    }

    .MainLogo {
      display: block;
    }

    &.inline {
      .MainLogo {
        display: inline-block;
      }

      .switch {
        margin: 0 0 0.5rem 4rem;
      }
    }
  }

  > .Wrap {
    border-bottom: 2px solid $white;
    padding: 1rem 0.5rem 0.5rem;
    @include xs {
      padding-left: 1.75rem;
      padding-right: 1.75rem;
    }

    // @include md {
    //   padding-left: 0.5rem;
    //   padding-right: 0.5rem;
    //   padding-top: 1rem;
    // }

    @include lg {
      border: 0;
      padding-left: 2rem;
      padding-right: 2rem;
    }
    @include xl {
      padding-left: 3rem;
      padding-right: 3rem;
    }
  }
}

#menu {
  color: $white;
  display: none;
  float: right;
  padding: 2.5rem 0 0;

  // @include md {
  //   display: block;
  //   padding: 1rem 0;
  // }

  @include lg {
    display: block;
    // padding-top: 1.5rem;
  }
}

#mobile-menu-cont {
  float: right;
  @include lg {
    display: none;
  }
}

#mobile-menu {
  background: $black-bis;
  bottom: 0;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  left: 0;
  padding-top: 8rem;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;

  .mobile-links-wrapper {
    @include md {
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: space-evenly;
    }
  }

  .children {
    border-radius: unset;
    left: 0;
    position: absolute;
    width: 100vw;

    .child-link {
      line-height: 0.1rem;
      text-align: left;
    }
  }

  .MenuLink {
    border-bottom: unset;
    font-size: 1rem;
    padding: unset;

    > a, .parent-link {
      margin-left: 2rem;
    }
  }

  > .bottom {
    color: $white;
    display: flex;
    flex-direction: column;

    .AuthMenu {
      display: block;
    }

  }
}

#hamburger {
  background: $yellow;
  border-radius: $radius;
  color: $text-color;
  cursor: pointer;
  float: right;
  font-size: $h3;
  height: $_burger-width;
  line-height: $_burger-width;
  margin-top: 0.5rem;
  outline: 2px solid $white;
  outline-offset: 0.3rem;
  position: relative;
  text-align: center;
  width: $_burger-width;
  z-index: 13;
  @include maxSm {
    display: inline-block;
  }
  @include md {
    font-size: $h2;
    height: $_burger-width-md;
    line-height: $_burger-width-md;
    margin-top: 1rem;
    width: $_burger-width-md;
  }
}

// @animation
.slide-side-enter-active,
.slide-side-leave-active {
  @include trans;
  opacity: 1;
  transform: translateX(0);
}

.slide-side-enter,
.slide-side-leave-to {
  opacity: 0;
  transform: translateX(300px);
}

// toggler

.switch {
  background: transparent;
  display: inline-block;
  height: 36px;
  margin: 0 0 0.5rem 4rem;
  position: relative;
  width: 18rem;
}

.switch input {
  display: none;
}

.slider {
  align-items: center;
  border-radius: 34px;
  bottom: 0;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  left: 0;
  outline: 2px solid $white;
  padding: 1rem;
  position: absolute;
  right: 0;
  top: 0;

  > .option {
    @include trans(1s);
    color: $white;
    font-size: 13px;
    font-weight: $bold;
    letter-spacing: 2px;
    text-transform: uppercase;
    user-select: none;
    z-index: 1;

    > .Icon {
      display: inline-block;
      padding-right: 0.5rem;
    }

    &.checked {
      @include trans(1s);
      color: $text-color;
    }
  }
}

.slider::before {
  background: $yellow;
  background-color: $yellow;
  border-radius: 15px;
  content: "";
  height: 26px;
  left: 0.3rem;
  position: absolute;
  transition: 1s;
  width: 9.2rem;
}

.slider.checked {
  &::before {
    transform: translateX(9.5rem);
    width: 7.8rem;
  }
}

// input:checked + .slider {
//   background-color: #101010;
// }

// input:focus + .slider {
//   box-shadow: 0 0 1px $black;
// }

// input:checked + .slider::before {
//   transform: translateX(9.1rem);
// }
</style>
