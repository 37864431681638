<template>
  <div id="Index">
    <!-- <Hero
      :formats="['webp', 'png']">
      <div slot="title">
        Prisijunkite<br v-if="!isDesktop"> prie<br v-if="isDesktop"> saulės bendruomenės
      </div>
      <div slot="customSubtitle">
        <h4>
          Naujas saulės parkas „Rudamina“ jau priima rezervacijas!<br>
          Numatoma, jog parkas elektros energiją pradės generuoti kitų metų spalio mėnesį!
        </h4>
      </div>
      <div slot="actions">
        <Route class="Btn ib danger" to="Signup">
          Rezervuok
          <Icon name="right" />
        </Route>
        <Route class="Btn ib" :to="{ name: 'Index', hash: '#video' }">
          Sužinok daugiau
          <Icon name="right" />
        </Route>
      </div>
    </Hero> -->
    <HeroHomepage />

    <!-- <div id="video">
      <Wrap class="xl">
        <Feature
          image="../assets/landing/video.png"
          :isLeft="!isDesktop"
          title="Rezervuokite elektrinės dalį saulės parke"
          video="https://www.youtube.com/embed/by3-0VcAgFA">
          <p>
            Saulės elektrinę iš saulės parko įsigyti yra ypač paprasta – jums nereikia rūpintis nei saulės elektrinės įrengimu, nei jos priežiūra. „Saulės bendruomenės“ komanda nuolat stebi elektrinės veikimą ir taip užtikrina, kad saulės elektrinė veiktų pilnu galimu pajėgumu ištisus metus. Gaminti ir vartoti saulės energiją iš saulės parkų gali visi LR gyventojai. Taigi butų savininkai, privačių namų savininkai ir bendrijos gali lengvai pradėti naudoti saulės energiją ir taip sumažinti savo elektros išlaidas net iki 70%.
          </p>
        </Feature>
      </Wrap>
    </div> -->

    <SectionProjects />
    <SectionApva />
    <CalculatorReservable />
    <SectionHowToBecome />
    <SectionNews />

    <!-- <ProjectAbout>
      <div class="why">
        <h2>Saulės bendruomenė -<br v-if="!isDesktop"> kodėl verta?</h2>
        <div>
          <Wrap class="lg">
            <IconColumns classes="inv" :data="why" />
          </Wrap>
        </div>
      </div>
    </ProjectAbout>

    <HowItWorks
      id="kaip-veikia"
      v-observe-visibility="{ callback: isVisible => trackView(isVisible), once: true }" /> -->

    <!-- <FooterLanding /> -->
    <Footer />
  </div>
</template>

<script>
import CalculatorReservable from './components/CalculatorReservable'
// import Feature from './components/landing/Feature'
// import FooterLanding from './components/landing/FooterLanding'
import Footer from './components/Footer'
import HeroHomepage from './components/landing/HeroHomepage'
// import HowItWorks from './components/landing/HowItWorks'
// import IconColumns from './components/landing/IconColumns'
import Meta from './mixins/Meta'
// import ProjectAbout from './components/landing/ProjectAbout'
import SectionApva from './components/landing/SectionApva'
import SectionHowToBecome from './components/SectionHowToBecome'
import SectionNews from './components/SectionNews'
import SectionProjects from './components/SectionProjects'
import { getters } from './utils'
import why from './components/landing/why.json'

export default {
  mixins: [
    Meta(
      {
        inner: 'Saulės elektros energija',
        separator: '|',
        complement: 'Saulės bendruomenė',
      },
      undefined,
      require('@/assets/social/homepage.jpg'),
    ),
  ],
  components: {
    CalculatorReservable,
    Footer,
    // Feature,
    // FooterLanding,
    HeroHomepage,
    // HowItWorks,
    // IconColumns,
    // ProjectAbout,
    SectionApva,
    SectionHowToBecome,
    SectionProjects,
    SectionNews,
  },
  data() {
    return {
      amount: 200,
      why,
    }
  },
  computed: getters('isDesktop', 'mainProject'),
  methods: {
    trackView(isVisible, isRetry) {
      if (!isVisible) return

      try {
        this.$analytics.fbq.event('ViewContent')
      } catch (error) {
        if (isRetry) return

        setTimeout(() => {
          this.trackView(isVisible, true)
        }, 2000)
      }
    },
  },
}
</script>

<style lang="scss">
#Index {
  @include customScroll;
  background: $white-ter;

  .Calculator {
    contain: content;
    padding-bottom: 0;
    padding-top: 4rem;

    h2 {
      @include dotAbove(1.5rem);
    }
  }

  .HowItWorks {
    contain: content;
  }

  .SectionNews {
    padding-bottom: 4rem;
    padding-top: 8rem;
  }
}

</style>
