<template>
  <Sect id="SectionHowToBecome">
    <Wrap class="lg">
      <h2>Kam aktualūs saulės parko sprendimai?</h2>
      <Row>
        <Column v-for="(card, index) in cards" :key="index" :lg="3" :md="6">
          <CardFlip :card="card" />
        </Column>
      </Row>
    </Wrap>
  </Sect>
</template>

<script>
import CardFlip from './CardFlip'
import { getters } from 'views/utils'

export default {
  components: { CardFlip },
  data() {
    return {
      cardsIndividual: [
        {
          background: '../assets/howToBecome/appartment-building.jpg',
          icon: 'apartment-building',
          label: 'Gyvenantiems daugiabutyje ',
          text: 'Saulės energija jau prieinama ir daugiabučių gyventojams. Įsigiję saulės parko dalį, daugiabučiuose gyvenantys asmenys už elektros energiją moka net iki 70% mažiau. Be to, nutolusios elektrinės dalies pagamintą energiją galima išskirstyti per kelis gyventojui priklausančius butus ar kitus turimus NT objektus.',
        },
        {
          background: '../assets/howToBecome/house-broken.jpg',
          icon: 'house-broken',
          label: 'Neturintiems tinkamo namo stogo elektrinei įsirengti',
          text: 'Saulės parkai – puikus sprendimas neturintiems tinkamų sąlygų įsirengti nuosavą elektrinę. Jeigu jūsų namo stogo konstrukcijos netinkamos montuoti saulės moduliams arba nepakanka stogo ploto, saulės energija apsirūpinkite iš nutolusios saulės elektrinės.',
        },
        {
          background: require('../../assets/howToBecome/seaside.jpg'),
          icon: 'tools',
          label: 'Norintiems nesirūpinti dėl saulės elektrinės įrengimo bei priežiūros',
          text: 'Vienas iš saulės parko privalumų – ypač paprastas saulės parko dalies įsigijimo ir naudojimo procesas. Mes pasirūpinsime visais saulės elektrinės įrengimo etapais, pradedant saulės elektrinės statyba, baigiant nuolatine saulės elektrinės priežiūra ir aptarnavimu.',
        },
        {
          background: require('../../assets/howToBecome/house-fancy.jpg'),
          icon: 'house-fancy',
          label: 'Statantiems A++ energetinės klasės namus',
          text: 'Nuo 2021 metų įsigaliojęs A++ energetinio naudingumo klasės reikalavimas naujai statomiems namams nurodo, kad daugiau nei 50% energijos pastate turi būti iš atsinaujinančios energijos šaltinių. Saulės parkai suteikia puikią galimybę išpildyti šį reikalavimą.',
        },
      ],
      cardsBusiness: [
        {
          background: require('../../assets/howToBecome/appartment-building.jpg'),
          icon: 'bulb',
          label: 'Smulkiam bei vidutiniam verslui',
          text: 'Nutolę saulės parkai palanki alternatyva smulkaus bei vidutinio verslo atstovams.',
        },
        {
          background: require('../../assets/howToBecome/house-broken.jpg'),
          icon: 'solar-panel',
          label: ' Neturintiems tinkamų sąlygų saulės elektrinei įsirengti',
          text: 'Saulės parkai – puikus sprendimas neturintiems tinkamų sąlygų įsirengti nuosavą elektrinę. Jeigu jūsų pastato stogo konstrukcijos netinkamos montuoti saulės moduliams arba nepakanka stogo ploto, saulės energija apsirūpinkite iš nutolusios saulės elektrinės.',
        },
        {
          background: require('../../assets/howToBecome/seaside.jpg'),
          icon: 'tools',
          label: 'Norintiems nesirūpinti dėl saulės elektrinės įrengimo bei priežiūros',
          text: 'Vienas iš saulės parko privalumų – ypač paprastas saulės parko dalies įsigijimo ir naudojimo procesas. Mes pasirūpinsime visais saulės elektrinės įrengimo etapais, pradedant saulės elektrinės statyba, baigiant nuolatine saulės elektrinės priežiūra ir aptarnavimu.',
        },
        {
          background: require('../../assets/howToBecome/house-fancy.jpg'),
          icon: 'hand-world',
          label: 'Siekiantiems tapti tvaresniu verslu',
          text: 'Nutolusio saulės parko dalis ne tik padės sumažinti elektros energijos išlaidas, bet ir ženkliai prisidės prie verslo tvarumo strategijos įgyvendinimo.',
        },
      ],
    }
  },
  computed: {
    cards() {
      return this.isBusinessMode
        ? this.cardsBusiness
        : this.cardsIndividual
    },
    ...getters('isBusinessMode'),
  },
}
</script>

<style lang="scss">
#SectionHowToBecome {
  @include roundSvgDown('../../assets/ellipse.svg');
  background: $black-bis;
  display: flex;
  flex-direction: column;
  padding: 15rem 0 8rem;
  position: relative;

  h2 {
    @include dotAbove(1.5rem);
    color: $white;
    margin-bottom: 4rem;
  }

  .CardFlip {
    margin: 1rem;
  }
}
</style>
