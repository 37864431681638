import {
  email,
  max,
  max_value as maxValue,
  min,
  min_value as minValue,
  required,
} from 'vee-validate/dist/rules'
import { extend } from 'vee-validate'

extend('min', {
  ...min,
  message: 'Per trumpas',
})
extend('max', {
  ...max,
  message: 'Per ilgas',
})
extend('min_value', {
  ...minValue,
  message: 'Per mažas kiekis',
})
extend('max_value', {
  ...maxValue,
  message: 'Per didelis kiekis',
})
extend('email', {
  ...email,
  message: 'Neteisingas el. pašto adresas',
})
extend('required', {
  ...required,
  message: 'Privaloma',
})
extend('password', {
  validate: value => /^[\w-!#$%&()*+,.;=@^~ąčėęįšūųž]*$/i.test(value),
  message:
    'Slaptažodis gali turėti tik lotyniškas, lietuviškas raides, skaičius ir skyrybos ženklus',
})
extend('passwordNumber', {
  validate: value => /\d/.test(value),
  message: 'Slaptažodis privalo turėti bent 1 skaičių.',
})
extend('passwordNotAllNumeric', {
  validate: value => !!value.replace(/\d/g, '').length,
  message: 'Slaptažodis negali būti vien iš skaičių.',
})
extend('passwordMatch', {
  validate: (value, { other }) => value === other,
  message: 'Nesutampa slaptažodžiai.',
  params: [{ name: 'other', isTarget: true }],
})
extend('phone', {
  validate: value => /^[\d +-]{6,}$/.test(value),
  message: 'Neatrodo kaip tikras telefono numeris',
})
extend('checkedBox', {
  validate: value => value === true,
  message: 'Reikia sutikti su privatumo politika, kad galėtume apdoroti duomenis',
})
extend('allLetters', {
  validate: value => /^[A-z-]+$/.test(value),
  message: 'Galima įrašyti tik raides, _ ir -',
})
extend('companyCode', {
  validate: value => /^\d+$/.test(value),
  message: 'Netinkamas formatas. Įmonės kodą sudaro tik skaitmenys.',
})
extend('companyVatCode', {
  validate: value => /^\w{1,3}\d+$/.test(value),
  message: 'Netinkamas formatas. Pvz.: LT123456789',
})
