<template>
  <div
    class="ModalClose"
    @click="$emit('close')"
    @keypress.enter="$emit('close')"
    @keypress.esc="$emit('close')">
    <Icon name="cross" />
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss">
.ModalClose {
  color: $text-color;
  cursor: pointer;
  font-size: 1.5rem;
  position: absolute;
  right: 0.75rem;
  top: 0.75rem;
}
</style>
