<template>
  <div
    class="CardFlip"
    :class="{ round: isActive }"
    @mouseleave="isActive = false"
    @mouseover="isActive = true"
    @touchend="isActive = !isActive">
    <Card v-show="!isActive" class="cover">
      <Icon :name="card.icon" />
      <h3>{{ card.label }}</h3>
    </Card>
    <Card v-show="isActive" class="details">
      <Pic :src="card.background" />
      <div class="imgOverlay" />
      <div class="content">
        <h3>{{ card.label }}</h3>
        <p>{{ card.text }}</p>
      </div>
    </Card>
  </div>
</template>

<script>
export default {
  props: {
    card: Object,
  },
  data() {
    return {
      isActive: false,
    }
  },
}
</script>

<style lang="scss">
.CardFlip {
  background: $white;
  border-radius: $radius 0;
  font-weight: $bold;
  height: 30rem;

  > .Card {
    padding: 0;

    h3 {
      font-weight: $bold;
    }
  }

  .cover {
    background: $white;
    border-radius: $radius 0;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    padding: 3rem;
    width: 100%;

    h3 {
      font-size: $h25;
      @include lg {
        font-size: $h3;
      }
    }

    .Icon {
      color: $blue;
      font-size: 10rem;
      @include lg {
        font-size: 6rem;
      }
    }

  }

  // .wrap {
  //   padding: 2rem 3rem 0;
  // }

  .details {
    background: $yellow;
    border-radius: $radius;
    color: $white;
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
    position: relative;
    white-space: normal;
    width: 100%;

    .imgOverlay {
      background-color: $blue;
      height: 100%;
      opacity: 0.85;
      overflow: hidden;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 1;
    }

    img {
      height: 100%;
      overflow: hidden;
      position: absolute;
      top: 0;
      z-index: 1;
    }

    .content {
      display: flex;
      flex-direction: column;
      gap: 2rem;
      height: 100%;
      justify-content: center;
      padding: 0 2rem;
      z-index: 2;

      p {
        font-size: 1.1rem;
        line-height: $lh * 1.1;
      }

      // .label {
      //   padding: 2rem 0;
      // }

      // p {
      //   padding: 2rem;
      // }
    }

  }

  &.round {
    border-radius: $radius;
  }

  // &:hover, &:active {
  //   .cover {
  //     display: none;
  //   }

  //   .details {
  //     display: block;
  //   }
  // }
}
</style>
