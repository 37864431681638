import { timeout } from 'views/utils/async'

export default {
  auto: ['actions'],
  store: {
    state: {
      downloadCounter: 0,
    },
    actions: {
      DOWNLOAD({ commit, state }, url) {
        const iframe = document.createElement('iframe')
        iframe.id = `downloader${state.downloadCounter}`
        iframe.style.display = 'none'
        iframe.dataset.cy = 'downloaderFrame'
        document.body.append(iframe)
        iframe.src = url
        commit('INC_DOWNLOAD_COUNTER')
      },
      async DOWNLOAD_FILE({ dispatch }, { id, fileId }) {
        dispatch('DOWNLOAD', (await dispatch('GET_FILE_DOWNLOAD', fileId || id)).url)
      },
      async DOWNLOAD_ALL({ dispatch }, { fileId, fileIds }) {
        return Promise
          .all((fileIds || [fileId]).map(async (fileId, index) => {
            await timeout(index * 100)
            dispatch('DOWNLOAD_FILE', { fileId })
          }))
      },
    },
    mutations: {
      INC_DOWNLOAD_COUNTER(state) {
        state.downloadCounter += 1
      },
    },
  },
}
