import { concat, curry, mergeWith, uniq } from 'rambda'
import { isPlainObject } from 'views/utils/guards'

const { entries, fromEntries, keys } = Object

export const mergeDeepRight = mergeWith((a, b) => {
  if (Array.isArray(a)) return [...a, ...b]

  const keysAll = uniq(concat(keys(a), keys(b)))

  return fromEntries(keysAll
    .map((key) => {
      const aValue = a[key]
      const bValue = b[key]

      if (typeof bValue === 'undefined') return aValue

      return (isPlainObject(bValue) && isPlainObject(aValue))
        ? mergeDeepRight(aValue, bValue)
        : bValue
    })
    .map((value, index) => [keysAll[index], value]))
})

export const mapEntries = curry((transform, object) => fromEntries(
  entries(object).map(transform),
))

export const mapValues = curry((transform, object) => mapEntries(
  ([key, value]) => [
    key,
    transform(value),
  ],
  object,
))
