export default () => ({
  feeMonthly: 2.6378,
  lifetimeYears: 25,
  omAnnual: 15.724793,
  priceKwh: 0.137,
  // subsidy: 322.91,
  subsidy: 323,
  yieldAnnual: 1032,
  vatRate: 0.21,
})
