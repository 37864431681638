/* global document, ga, hj, window */

import { META_REFERRERS } from '@/store/consts'
import Modernizr from 'modernizr'
import Vue from 'vue'
import { softThrow } from '../../bugsnag'

const getUrlParams = () => {
  try {
    const searchParams = new URLSearchParams(window.location.search)

    const newReferrers = Object.fromEntries(
      META_REFERRERS.map(tag => [
        tag, searchParams.get(tag),
      ]))

    if (Object.values(newReferrers).some(Boolean)) return newReferrers

    return null
  } catch (error) {
    return null
  }
}

export default {
  store: {
    state: {
      screenSizes: ['xs'],
      screenWidth: 320,
      referrers: getUrlParams(),
      isBusinessMode: false,
      url: null,
      visited: [],
    },
    actions: {
      SET_COOKIE(_, { key, value, expiration = '1y' }) {
        Vue.$cookies.set(key, value, expiration)
      },
      SET_MODE({ commit }, isBusinessMode) {
        commit('SET_MODE', isBusinessMode)
      },
      async CHECK_SCREEN_SIZE({ commit }) {
        await Vue.nextTick()
        const width = Math.max(document.documentElement.clientWidth, window.innerWidth || 0)
        commit('SET_SCREEN_WIDTH', width)
        commit('SET_SCREEN_SIZES', [
          ['xxs', 0],
          ['xs', 260],
          ['sm', 576],
          ['md', 768],
          ['lg', 992],
          ['xl', 1200],
          ['xxl', 1680],
        ].filter(([_, minWidth]) => width >= minWidth)
          .map(([size, _]) => size))
      },
      PAGEVIEW({ commit, getters }, { to, from }) {
        commit('SET_URL', to.path)
        commit('ADD_VISITED', to.path)

        // interface in the future
        if (!getters.isAdmin && from.name !== null) {
          const stats = {
            ga: path => ga('send', 'pageview', path),
            hj: path => hj('stateChange', path),
          }

          Object.keys(stats)
            .filter(key => window[key])
            .forEach((key) => {
              stats[key](to.path)
            })
        }
      },
      // GEVENT({ commit, getters }, { category, action = 'Click', label } = {}) {
      //   if (!getters.isAdmin) {
      //     ga('send', 'event', category, action, label)
      //   }
      // },
      ON({ dispatch }, events) {
        Object.keys(events).forEach((eventName) => {
          const passiveEvents = ['mousewheel', 'scroll', 'resize']

          const options =
            Modernizr.passiveeventlisteners && passiveEvents.includes(eventName)
              ? { passive: true }
              : false

          window.addEventListener(eventName, events[eventName], options)
        })

        return () => dispatch('OFF', events)
      },
      OFF(_, events) {
        Object.keys(events).forEach((eventName) => {
          window.removeEventListener(eventName, events[eventName])
        })
      },
      // GO_BACK({ state }, { $router, fallback = 'Index' }) {
      //   if (state.visited.length > 1 && state.visited[0] !== state.url) {
      //     $router.go(-1)
      //   } else {
      //     $router.push({ name: fallback })
      //   }
      // },
    },
    mutations: {
      SET_MODE(state, isBusinessMode) {
        state.isBusinessMode = isBusinessMode
      },
      SET_SCREEN_SIZES(state, screenSizes) {
        state.screenSizes = screenSizes
      },
      SET_SCREEN_WIDTH(state, screenWidth) {
        state.screenWidth = screenWidth
      },
      SET_URL(state, path) {
        state.url = path
      },
      ADD_VISITED(state, path) {
        state.visited.push(path)
      },
    },
    getters: {
      isDesktop: (_, getters) => getters.screenSizes.includes('md'),
      screenSizes: state => state.screenSizes,
      screenWidth: state => state.screenWidth,
      url: state => state.url,
      referrers: state => {
        try {
          return state.referrers || JSON.parse(Vue.localStorage.get('referrers'))
        } catch (error) {
          softThrow(error)
          return null
        }
      },
      isBusinessMode: state => {
        try {
          // if (typeof state.isBusinessMode === 'boolean')
          return state.isBusinessMode

          // return JSON.parse(Vue.localStorage.get('isBusinessMode'))
        } catch (error) {
          softThrow(error)
          return false
        }
      },
      visited: state => state.visited,
    },
  },
}
