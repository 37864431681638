export const joinReferences = {
  PARENT: 1,
  CHILD: 2,
  PIVOT: 3,
}

export const CONTRACT_TYPES = {
  RESERVATION: 'RESERVATION',
  MAIN: 'MAIN',
  TRANSFER_ACCEPTANCE: 'TRANSFER_ACCEPTANCE',
}

export const CONTRACT_CANCEL_REASONS = {
  UNILATERAL_PROVIDER: 'UNILATERAL_PROVIDER',
}

export const PAYMENT_TYPES = {
  ADVANCE: 'ADVANCE',
  RESERVATION: 'RESERVATION',
  MAIN: 'MAIN',
  MAINTENANCE: 'MAINTENANCE',
}

export const AMENDMENT_TYPES = {
  CAPACITY_CHANGE: 'CAPACITY_CHANGE',
}

export const APVA_CHOICES = {
  IS_PLANNING: 'Planuoju teikti paraišką',
  HAS_APVA: 'Paramą turiu',
  NO_APVA: 'Įsigysiu be paramos',
}

export const META_REFERRERS = [
  'r',
  'utm_campaign',
  'utm_source',
  'utm_medium',
  'utm_term',
  'utm_content',
]

export const LIMIT_MAX = 99999
