import { factory } from '../utils'

export default {
  auto: '*',

  store: {
    getters: {
      esoSnapshotFactory: factory(() => ({
        authorUserId: null,
        projectId: null,
        isCombined: false,
      })),
    },
  },
}
