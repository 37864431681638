export default {
  auto: '*',
  store: {
    getters: {
      getFileExtension: (_, { fileExtensions }) => ({ extension }) =>
        fileExtensions[extension] || fileExtensions.etc,

      fileExtensions: () => ({
        docx: { theme: 'info', icon: 'file' },
        pdf: { theme: 'danger', icon: 'file' },
      }),
    },
  },
}
