<template>
  <div class="Tag">
    <slot />
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss">
.Tag {
  background: $blue;
  border-radius: $radius;
  color: $white;
  display: inline-block;
  font-size: $h7;
  font-weight: $semibold;
  letter-spacing: 0.2rem;
  line-height: 1.75rem;
  padding: 0 0.5rem;
  text-align: center;
  text-transform: uppercase;

  // > .dot {
  //   background: $main;
  //   border-radius: $radius-round;
  //   display: inline-block;
  //   height: 0.5rem;
  //   margin-right: 0.25rem;
  //   width: 0.5rem;
  // }

  // size
  &.lg {
    font-size: $h5;
  }

  &.sm {
    font-size: $h6;
    line-height: 1rem;
    padding: 0.25rem 0.5rem;
  }
  // &.xs {
  //   font-size: $h7;
  //   line-height: 0.9rem;
  //   padding: 0 0.25rem;
  // }

  // type
  // &.db {
  //   display: block;
  // }
  // &.with-icon {
  //   padding: 0 0.75rem 0 0.625rem;
  // }

  // theme
  &.inv {
    background: transparent;
    border: 1px solid $main;
    color: $main;
  }

  &.danger {
    background: $danger;
    //   &.inv {
    //     color: $danger;
    //   }
  }

  &.info {
    background: $info;
    // &.inv {
    //   color: $info;
    // }
  }

  &.success {
    background: $success;
    // &.inv {
    //   color: $success;
    // }
  }
  // &.warning {
  //   background: $warning;
  //   color: $text-color;
  //   &.inv {
  //     color: $warning;
  //   }
  // }
  // &.neutral {
  //   background: $grey-lightest;
  //   color: $text-color-mid;
  //   .Icon {
  //     &:hover {
  //       color: #000;
  //     }
  //   }
  // }

  // shape
  &.round {
    border-radius: $radius-round;
    height: 1.5rem;
    line-height: 1.5rem;
    width: 1.5rem;
  }

  &.angular {
    border-radius: $radius-md;
  }
  // &.right {
  //   float: right;
  // }

  // .Field {
  //   display: inline-block;
  //   margin-bottom: 0;
  //   width: auto;
  //   .with-icon {
  //     width: auto;
  //     > .Icon {
  //       line-height: $lh;
  //     }
  //   }
  //   select, option {
  //     color: inherit;
  //     line-height: 0.5 * $lh;
  //     padding: 0 0.25rem;
  //   }
  // }
}

</style>
