import { isPlainObject } from '@/views/utils/guards'

export const debounce = (timeMs, method) => {
  let timeout

  return function(...methodArguments) {
    // eslint-disable-next-line promise/avoid-new
    return new Promise((resolve) => {
      const later = () => {
        timeout = null

        resolve(method.apply(this, methodArguments))
      }

      timeout && clearTimeout(timeout)
      timeout = setTimeout(later, timeMs)
    })
  }
}

export const intoSequentialPromise = async (previous, current, index) => [
  ...await previous,
  await current(index),
]

// eslint-disable-next-line promise/avoid-new
export const timeout = timeMs => new Promise((resolve) => {
  setTimeout(resolve, timeMs)
})

export const unwrapObjectPromisesDeep = (() => {
  const isPromise = value =>
    value &&
    typeof value === 'object' &&
    // eslint-disable-next-line promise/prefer-await-to-then
    typeof value.then === 'function'

  const unwrapAsyncValue = async (value) => {
    if (isPromise(value)) return value
    if (isPlainObject(value)) return unwrapObjectPromisesDeep(value)
    if (Array.isArray(value)) return Promise.all(value.map(unwrapAsyncValue))

    return value
  }

  return async object => Object
    .fromEntries(await Promise.all(Object
      .entries(object)
      .map(async ([key, value]) => [
        key,
        await unwrapAsyncValue(value),
      ])))
})()
