<template>
  <div class="AuthMenu" :data-cy="`isAuth:${isAuth}`">
    <MenuLink
      v-if="screenSizes.includes('lg')"
      :link="authMenu" />
    <div v-else>
      <div v-if="isAuth" class="mobile">
        <Route
          v-if="hasRole('>=SUPPORT')"
          to="AdminDashboard">
          <span>ADMIN</span>
          <Icon name="arrow-right" />
        </Route>
        <Route
          to="SelfProjects">
          <span>SAVITARNA</span>
          <Icon name="arrow-right" />
        </Route>
        <Route to="Logout">
          <span>ATSIJUNGTI</span>
          <Icon name="arrow-right" />
        </Route>
      </div>
      <div v-else class="mobile">
        <Route to="Login">
          <span>PRISIJUNGTI</span>
          <Icon name="arrow-right" />
        </Route>
      </div>
      <div class="modeSwitch" role="button" @click="togglePageMode">
        <span>{{ isBusinessMode ? 'PRIVATIEMS' : 'VERSLUI' }}</span>
        <Icon name="arrow-right" />
      </div>
    </div>
  </div>
</template>

<script>
import { actions, getters } from 'views/utils'
import MenuLink from './MenuLink'

export default {
  components: { MenuLink },
  computed: {
    authMenu() {
      if (!this.isAuth) {
        return {
          label: 'Prisijungti',
          children: [
            { label: 'Prisijungti', to: 'Login' },
            { label: 'Registruotis', to: 'Signup' },
          ],
          clickHandler: '',
        }
      }

      return {
        label: 'Paskyra',
        children: [
          { label: 'Savitarna', to: 'SelfProjects' },
          ...this.hasRole('>=SUPPORT')
            ? [{ label: 'Admin', to: 'AdminDashboard' }]
            : [],
          { label: 'Atsijungti', to: 'Logout' },
        ],
        clickHandler: '',
      }
    },
    ...getters('isDesktop', 'isBusinessMode', 'screenSizes'),
  },
  methods: {
    togglePageMode() {
      this.SET_MODE(!this.isBusinessMode)
      // this.$emit('setCheckboxVal', this.isBusiness)
    },
    ...actions('SET_MODE'),
  },
}
</script>

<style lang="scss">
.AuthMenu {
  color: $white;
  display: inline-block;
  margin-left: 0.25rem;

  // .Btn {
  //   font-size: $h5;
  // }

  // @include xs {
  //   margin-left: 0.25rem;
  // }

  // @include md {
  //   margin-left: 0.25rem; // breakpoints bugfix
  // }

  @include lg {
    margin-left: 0.7rem;
  }
  @include xl {
    margin-left: 3rem;
  }
  @include xxl {
    margin-left: 4rem;
  }

  .mobile {
    > a {
      align-items: center;
      border-top: 2px solid $white;
      display: flex;
      font-size: 1rem;
      justify-content: space-between;
      margin: 0 2rem;
      padding: 2.5rem 0;
    }
  }

  .modeSwitch {
    align-items: center;
    border-top: 2px solid $white;
    display: flex;
    // height: 5rem;
    justify-content: space-between;
    padding: 2.5rem 2rem;
  }

  .parent-link {
    @include dotBefore(2rem);
  }

  a {
    color: $white;
    font-size: 0.9rem;
    margin-right: 0.5rem;
    padding: 0.4rem 0.6rem;
    @include xl {
      font-size: 1.1rem;
    }
    @include xxl {
      font-size: 1.3rem;
      padding: 0.4rem 1rem;
    }

    // &:last-child {
    //   margin-right: 0;
    // }
  }

}
</style>
