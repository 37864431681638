import authMap from './authMap'
import router from '../router'
import store from '@/store'

function checkRoute(from, to) {
  return doChecks(from, to).catch((error) => {
    router.replace({ name: (error && error.code) ? `Err${error.code}` : 'Login' })
  })
}

async function doChecks(from, to) {
  const auth = authMap[to.name]
  if (!auth) return Promise.resolve()

  await store.dispatch('CHECK_LOGIN')

  return doAllAuth(auth, from, to)
}

const passAuth = (from, to) => conditions => Promise
  .all(conditions.map(condition => condition({ from, router, store, to })))

async function doAllAuth(auth, from, to) {
  // always enforce logged in auth
  if (!store.getters.isAuth) return Promise.reject(new Error('Not logged in!'))

  const authResult = await passAuth(from, to)(auth)

  if (!authResult.some(authPass => authPass)) {
    const error = new Error('Unauthorized')
    error.code = 401
    throw error
  }
}

export { checkRoute, doChecks }
