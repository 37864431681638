<!-- eslint-disable sonarjs/no-duplicate-string -->
<template>
  <Sect id="SectionProjects">
    <h2>Saulės parkai</h2>
    <div v-if="projects" class="mainContainer">
      <HorizontalScroll :itemsCount="projectsCount">
        <CardProject
          v-for="(project, index) in projects"
          :key="index"
          :project="project" />
      </HorizontalScroll>
    </div>
    <div class="viewMore">
      <Route class="Btn arrow" to="SolarPlants">
        VISI SAULĖS PARKAI
      </Route>
    </div>
  </Sect>
</template>

<script>
import CardProject from './CardProject'
import HorizontalScroll from './HorizontalScroll'
// import ProjectMocker from 'mixins/ProjectMocker'
import ProjectBundler from 'mixins/ProjectBundler'

export default {
  mixins: [ProjectBundler],
  components: { CardProject, HorizontalScroll },
  computed: {
    projectsCount() {
      return this.projects.length
    },
  },
}
</script>

<style lang="scss">
#SectionProjects {
  background: $white-ter;
  // display: flex;
  position: relative;

  h2 {
    @include dotAbove(1.5rem);
    padding-bottom: 2rem;
  }

  .mainContainer {
    display: flex;
    position: relative;
  }

  .CardProject {
    // display: inline-block;
    // margin-right: 15px;
    min-width: 85vw;
    @include md {
      min-width: 70vw;
    }
    @include xl {
      min-width: 60vw;
    }
  }

  .viewMore {
    display: flex;
    justify-content: center;
    margin: 4rem 0;

    > a {
      width: 18rem;
    }
  }
}
</style>
