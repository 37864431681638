import { factory } from '../utils'

export default {
  auto: '*',
  cacheFor: 3000,

  store: {
    getters: {
      projectPostFactory: factory(() => ({
        title: null,
        text: null,
        dateAt: new Date(),
        prefix: null,
        icon: null,
      })),
    },
  },
}
