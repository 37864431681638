<template>
  <router-link
    v-if="canAccess && !isExternal"
    class="routerLink"
    :exact="exact"
    :title="title"
    :to="route"
    @click.native="handleHash">
    <slot />
  </router-link>
  <a
    v-else
    class="routerLink"
    :href="to"
    target="_blank">
    {{ title }}
  </a>
</template>

<script>
import Link from '../../mixins/Link'
import { doChecks } from '../../../router/authorization'
import { getters } from '../../utils'

export default {
  mixins: [Link],
  props: {
    exact: {
      default: false,
      type: Boolean,
    },
    isExternal: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      canAccess: true,
    }
  },
  computed: getters(),
  methods: {
    doChecks() {
      doChecks(this.$route, this.route).catch(() => {
        this.canAccess = false
      })
    },
  },
  watch: {
    isAuth: {
      handler: 'doChecks',
      immediate: true,
    },
  },
}
</script>

<style lang="scss">
.routerLink {
  // border-bottom: none;
}
</style>
