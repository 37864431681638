<script>
export default {
  props: {
    event: Array,
  },
  methods: {
    track(action, label) {
      if (this.event) {
        this.$ga.event(this.event[0], this.event[1] || action, this.event[2] || label)
      }
    },
  },
}
</script>
