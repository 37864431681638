export function any(...conditions) {
  return conditionArguments => conditions.some(condition => condition(conditionArguments))
}

export function loggedIn() {
  return ({ store }) => store.getters.isAuth
}

export function hasRole(roleCode) {
  return ({ store }) => store.getters.hasRole(roleCode)
}
