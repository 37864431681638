import { factory } from '../utils'

export default {
  auto: '*',
  store: {
    getters: {
      contactFactory: factory(() => ({
        firstName: null,
        lastName: null,
        email: null,
        phone: null,
        address: null,
        homeType: null,
        powerUsage: null,
        hasAcceptedPolicy: false,
        hasSubscribed: false,
        comment: null,
      })),
    },
  },
}
