import { isJSONString, isObject } from '../../views/utils'

export default {
  store: {
    state: {
      $localStorage: null,
    },
    actions: {
      SET_STORE({ state }, entries) {
        Object
          .keys(entries)
          .forEach((key) => {
            const value = entries[key]
            const storedValue = isObject(value) ? JSON.stringify(value) : value
            state.$localStorage.set(key, storedValue)
          })
        return Promise.resolve()
      },
      SET_CAPACITY_CACHE({ dispatch }, capacity) {
        dispatch('SET_STORE', {
          capacityCache: capacity,
        })
      },
      INIT_STORE({ commit }, object) {
        commit('INIT_STORE', object)
      },
    },
    mutations: {
      INIT_STORE(state, { $localStorage }) {
        state.$localStorage = $localStorage
      },
    },
    getters: {
      localStore: state => (key) => {
        const storedValue = state.$localStorage.get(key)
        return isJSONString(storedValue) ? JSON.parse(storedValue) : storedValue
      },
    },
  },
}
