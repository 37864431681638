<template>
  <Sect id="SectionApva">
    <Wrap class="md">
      <h2>Gaukite APVA paramą ir <b>susigrąžinkite net iki 30% investicijos išlaidų.</b></h2>
      <!-- <h3>Sužinokite, kaip gauti valstybės paramą:</h3> -->
      <!-- <Row class="table">
        <Column class="item" :lg="6">
          <Icon name="people" />
          <div class="label">
            APVA PARAMA GYVENTOJAMS
          </div>
          <Route class="Btn arrow no-text" to="ApvaIndividuals" />
        </Column>
        <Column class="item" :lg="6">
          <Icon name="suitcase" />
          <div class="label">
            APVA PARAMA VERSLUI
          </div>
          <Route class="Btn arrow no-text" to="ApvaIndividuals" />
        </Column>
      </Row> -->
      <div class="item">
        <Icon name="people" />
        <div class="label">
          APVA PARAMA GYVENTOJAMS
        </div>
        <Route class="Btn arrow no-text" to="ApvaIndividuals" />
      </div>
    </Wrap>
  </Sect>
</template>

<script>
export default {
}
</script>

<style lang="scss">
#SectionApva {
  background: linear-gradient(0deg, rgba($blue, 0.8), rgba($blue, 0.8)), url('../../../assets/index/panels.jpg');
  background-size: cover;
  color: $white;
  margin: 0 0 6rem;
  // padding: 3rem 0 1rem;
  padding-top: 6rem;
  position: relative;

  h2 {
    color: inherit;
    font-size: $h2;
    font-weight: $regular;
  }

  .item {
    align-items: center;
    border-top: 2px solid $white;
    display: flex;
    flex-direction: column;
    font-size: $h3;
    font-weight: $bold;
    gap: 2rem;
    justify-content: center;
    padding: 2rem 0;
    // @include lg {
    //   &:first-of-type {
    //     border-right: 1px solid $white;
    //   }
    // }

    > .Icon {
      font-size: 5rem;
    }

    .Btn {
      border: 2px solid $white;
      width: unset;

      .circle {
        margin-left: -1rem;
      }
    }
  }

}
</style>
