<template>
  <Route
    class="CardPostSmall"
    :to="{ name: 'Post', params: { slug: post.slug } }">
    <div class="details wrap">
      <Tag>Naujiena</Tag>
      <div class="title">
        {{ post.title }}
      </div>
      <div class="date">
        <Icon name="calendar" />
        {{ new Date(post.date) | date }}
      </div>
    </div>
    <div class="preview">
      <Pic
        :src="post.image.url" />
    </div>
  </Route>
</template>

<script>
export default {
  props: {
    post: Object,
  },
}
</script>

<style lang="scss">
.CardPostSmall {
  background: $white-ter;
  border-bottom: unset;
  border-radius: $radius 0;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  @include lg {
    flex-direction: row;
  }

  .wrap {
    padding: 2rem 3rem 0;
  }

  .details {
    white-space: normal;
    width: 20rem;

    > * {
      margin-bottom: 1rem;
    }

    .title {
      @include ellipsisLines(3);
      font-size: $h4;
      font-weight: $bold;
      margin-bottom: 2rem;
    }

    .date {
      color: $blue;
    }
  }

  .preview {
    border-radius: $radius 0;
    height: 20rem;
    // height: 25rem;
    // opacity: 0.66;
    overflow: hidden;
    position: relative;
    width: 20rem;

    img {
      filter: brightness(0.8);
      height: 100%;
      object-fit: cover;
      width: 100%;
    }
  }
}
</style>
