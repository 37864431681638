<template>
  <div class="CapacitySlider">
    <div class="details">
      <div class="used">
        <div class="label">
          Rezervuota:
        </div>
        {{ used }} kW ({{ percent | percent }})
      </div>
      <div class="total">
        {{ total }} kW
      </div>
    </div>
    <Slider
      :disabled="true"
      :dotStyle="{
        display: 'none',
      }"
      :interval="sliderInterval"
      :max="total"
      :processStyle="{
        backgroundColor: '#4b6ce6',
      }"
      :railStyle="{
        background: 'transparent',
        height: '8px',
      }"
      tooltip="none"
      :value="used" />
  </div>
</template>

<script>
export default {
  components: {
    Slider: () => import(/* webpackChunkName: "slider" */ './Slider'),
  },
  props: {
    used: Number,
    total: Number,
    sliderInterval: {
      type: Number,
      default: 1,
    },
  },
  computed: {
    percent() {
      return (this.used / this.total) * 100
    },
  },
}
</script>

<style lang="scss">
.CapacitySlider {
  .details {
    align-items: flex-end;
    color: $white;
    display: flex;
    font-weight: $bold;
    justify-content: space-between;
    margin-bottom: 0.5rem;

    .label {
      font-size: 0.8rem;
      @include lg {
        display: inline-block;
      }
    }

    .used {
      font-weight: $bold;
    }

    > .used .total {
      font-size: 0.5rem;
      font-weight: $bold;
      text-align: center;
      @include lg {
        font-size: $h2;
        margin-top: 0.5rem;
      }
    }
  }

  .vue-slider-rail {
    outline: 1px solid $white;
    outline-offset: 0.3rem;
  }

  // size
  &.sm {
    > .used {
      @include lg {
        font-size: $h4;
        margin-top: 0;
      }
    }
  }
}
</style>
