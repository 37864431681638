<template functional>
  <span :class="['Icon', data.class, data.staticClass]" v-on="listeners">
    <i :class="`i-${props.name}`" />
    <slot />
  </span>
</template>

<script>
export default {
  props: {
    name: String,
  },
}
</script>

<style lang="scss">
@import '../../style/icons/style';

[class^="i-"],
[class*=" i-"] {
  line-height: inherit;
}

.Icon {
  position: relative;
}
</style>
