<template>
  <div id="Main">
    <transition appear :name="isHome ? 'slide-fade-down' : ''">
      <TopBar />
    </transition>
    <router-view />
    <!-- <Footer /> -->
  </div>
</template>

<script>
import TopBar from '../components/TopBar/TopBar'

export default {
  components: { TopBar },
  computed: {
    isHome() {
      return this.$route.name === 'Index'
    },
  },
}
</script>

<style lang="scss">
#Main {
  #Topbar {
    transition-delay: 1.2s;
  }
}

// @animation
.slide-fade-down-enter-active,
.slide-fade-down-leave-active {
  @include trans(1s);
}

.slide-fade-down-enter,
.slide-fade-down-leave-to {
  opacity: 0;
  transform: translateY(-50px);
}
</style>
