<script>
import { scrollToHash } from '../../router/smoothScroll'

export default {
  props: {
    id: Number,
    params: Object,
    title: String,
    to: [String, Object],
  },
  computed: {
    routeParams() {
      const params = {
        ...(this.params || {}),
      }

      if (this.id) params.id = this.id

      return params
    },
    route() {
      if (typeof this.to === 'string') {
        return {
          name: this.to,
          params: this.routeParams,
        }
      }

      return {
        ...this.to,
        params: {
          ...this.routeParams,
          ...(this.to.params || {}),
        },
      }
    },
  },
  methods: {
    handleHash(event) {
      if ((this.route.name && this.route.name !== this.$route.name) || !this.route.hash) return

      scrollToHash(this.route.hash)

      if (event) event.preventDefault()

      return true
    },
    go() {
      if (this.to) {
        if (typeof this.to === 'string' && this.to.startsWith('http')) {
          location.href = escape(this.to)
        } else {
          if (this.handleHash()) return

          this.$router.push(this.route)
        }
      }
    },
  },
}
</script>
