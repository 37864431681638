<template>
  <div
    v-show="allowCookies === null"
    id="CookieBar"
    :data-cy="allowCookies !== null ? 'hidden' : ''">
    <Row>
      <Column :md="9">
        <div class="message">
          Mes naudojame slapukus, kad galėtume suteikti pilną puslapio funkcionalumą. <a href="https://ec.europa.eu/info/cookies_lt" rel="noreferrer" target="_blank">
            Kas yra slapukai?
          </a>
        </div>
      </Column>
      <Column :md="3">
        <Row class="actions">
          <Column>
            <Btn class="info" icon="check" @click="setCookie(true)">
              Leisti slapukus
            </Btn>
          </Column>
          <Column>
            <Btn class="danger" icon="cross" @click="setCookie(false)">
              Ne, ačiū
            </Btn>
          </Column>
        </Row>
      </Column>
    </Row>
  </div>
</template>

<script>
import { actions, getters } from '../utils'

export default {
  data() {
    return {
      allowCookies: null,
    }
  },
  computed: getters('isDesktop', 'localStore'),
  methods: {
    setCookie(allowCookies) {
      this.allowCookies = allowCookies
      this.SET_STORE({ allowCookies })
    },
    ...actions('SET_STORE'),
  },
  mounted() {
    this.allowCookies = this.localStore('allowCookies')
  },
}
</script>

<style lang="scss">
#CookieBar {
  background: $neutral-light;
  bottom: 0;
  box-shadow: $shadow-lg;
  left: 0;
  padding: 0.5rem 0.5rem 1rem;
  position: fixed;
  right: 0;
  width: 100%;
  z-index: 7;
  @include md {
    padding: 1.5rem;
  }

  .message {
    padding: 1rem;
    @include md {
      padding: 1.5rem;
    }
  }

  .actions {
    text-align: center;

    .Btn {
      margin-bottom: 0.5rem;
      @include maxSm {
        padding: 0.5rem 1rem;
      }

      // &.info {
      //   background: $info-dark;
      //   border-color: $info-dark;
      // }

      // &.danger {
      //   background: $white;
      // }
    }
  }
}
</style>
