<script>
import { actions, getters } from 'views/utils'

export default {
  data() {
    return {
      isLoading: true,
    }
  },
  computed: {
    modules() {
      return this.project.solarModules
    },
    projectsPure() {
      return this.query('project')
    },
    projects() {
      return this.projectsPure.map(projectPure => {
        const solarModulesIds = projectPure.solarModules
        const solarModules = solarModulesIds.map(solarModuleId => ({
          module: this.query('solarModule', solarModuleId.id),
          count: solarModuleId.count,
        }))

        const solarInvertersIds = projectPure.solarInverters
        const solarInverters = solarInvertersIds.map(solarInverterId => ({
          inverter: this.query('solarInverter', solarInverterId.id),
          count: solarInverterId.count,
        }))

        return {
          ...projectPure,
          modules: solarModules,
          inverters: solarInverters,
          // construction: constructionMock,
        }
      }).sort((a, b) => a.order - b.order)
    },
    projectMain() {
      return this.projects.find(project => project.isMain)
    },
    projectsWithoutMain() {
      return this.projects.filter(project => project.id !== this.projectMain.id)
    },

    ...getters(),
  },
  methods: actions('FETCH_PROJECT', 'FETCH_SOLAR_MODULE', 'FETCH_SOLAR_INVERTER'),
  async mounted() {
    await Promise.all([
      this.FETCH_PROJECT(),
      this.FETCH_SOLAR_MODULE(),
      this.FETCH_SOLAR_INVERTER(),
    ])
    this.isLoading = false
  },
}
</script>

<style lang="scss">
</style>
