<template>
  <form @submit.prevent="submit">
    <ValidationObserver ref="obs">
      <slot />
      <transition appear name="slide-fade">
        <Notice v-if="errorMessage" class="danger">
          {{ errorMessage }}
        </Notice>
      </transition>
      <!-- <Emitter :value="errors" @change="emitError"/> -->
    </ValidationObserver>
  </form>
</template>

<script>
function flattenErrors(errorTree) {
  return Object
    .values(errorTree)
    .reduce((errors, field) => [
      ...errors,
      Array.isArray(field) ? field : flattenErrors(field),
    ], [])
    .flat()
}

export default {
  data() {
    return {
      errorMessage: null,
    }
  },
  methods: {
    emitError(errors) {
      this.errorMessage = flattenErrors(errors)[0] || null
    },
    reset() {
      return this.$refs.obs.reset()
    },
    async submit() {
      if (!await this.$refs.obs.validate()) {
        this.emitError(this.$refs.obs.errors)
        return
      }

      this.$emit('submit')

      requestAnimationFrame(() => {
        try {
          this.$refs.obs.reset()
        } catch (error) {}
      })
    },
  },
}
</script>
