<!-- eslint-disable sonarjs/no-duplicate-string -->
<template>
  <Sect class="SectionNews">
    <h2>{{ title }}</h2>
    <div class="mainContainer">
      <HorizontalScroll v-if="postsToShow" :itemsCount="postsCount">
        <!-- <div ref="projects" class="projects"> -->
        <CardPostSmall
          v-for="(post, index) in postsToShow"
          :key="index"
          :post="post" />
      <!-- </div> -->
      </HorizontalScroll>
    </div>
    <div class="viewMore">
      <Route class="Btn arrow" to="News">
        VISOS NAUJIENOS
      </Route>
    </div>
  </Sect>
</template>

<script>
import CardPostSmall from './CardPostSmall'
import HorizontalScroll from './HorizontalScroll'
import axios from 'axios'

export default {
  components: { CardPostSmall, HorizontalScroll },
  props: {
    title: {
      type: String,
      default: 'Naujienos',
    },
    skipPostId: Number,
  },
  data() {
    return {
      posts: null,
    }
  },
  computed: {
    postsCount() {
      return this.postsToShow.length
    },
    postsToShow() {
      if (!this.posts) return []

      return this.posts.filter(post => post.id !== this.skipPostId)
    },
  },
  async beforeMount() {
    const response = await axios
      .get('https://api.suninvestmentgroup.com/postSb?_sort=date:DESC')

    this.posts = response.data
  },
}
</script>

<style lang="scss">
.SectionNews {
  background: $white;
  padding-bottom: 4rem;
  padding-top: 8rem;

  h2 {
    @include dotAbove(1.5rem);
    margin-bottom: 4rem;
  }

  .viewMore {
    display: flex;
    justify-content: center;
    margin-top: 4rem;

    > a {
      width: 18rem;
    }
  }
}
</style>
