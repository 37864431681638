import { isNumeric, isObject, isObjectOrArray } from '.'
import sift, { createEqualsOperation } from 'sift'
import { _ } from '@feathersjs/commons'
import sorter from './sorter'

const customExpressions = {
  $like: (queryValue, ownerQuery, options) => createEqualsOperation(
    fieldValue => fieldValue && fieldValue.includes(queryValue),
    ownerQuery,
    options),

  $ilike: (queryValue, ownerQuery, options) => {
    const lowercaseValue = queryValue.toLowerCase()

    return createEqualsOperation(
      fieldValue => fieldValue && fieldValue.toLowerCase().includes(lowercaseValue),
      ownerQuery,
      options)
  },

  $contained: (queryValue, ownerQuery, options) => createEqualsOperation(
    fieldValue => fieldValue && fieldValue.every(item => queryValue.includes(item)),
    ownerQuery,
    options),

  $contains: (queryValue, ownerQuery, options) => createEqualsOperation(
    fieldValue => fieldValue && queryValue.every(item => fieldValue.includes(item)),
    ownerQuery,
    options),

  $overlap: (queryValue, ownerQuery, options) => createEqualsOperation(
    fieldValue => fieldValue && queryValue.some(item => fieldValue.includes(item)),
    ownerQuery,
    options),

  // custom
  $gteDate: (queryValue, ownerQuery, options) => {
    const queryTime = queryValue.getTime()

    return createEqualsOperation(
      (fieldValue) => {
        if (fieldValue === null) return false
        return queryTime <= fieldValue.getTime()
      },
      ownerQuery,
      options)
  },
}

// wrap $like and $ilike in %
function wrapInPercent(cleanQuery) {
  const fieldsToWrap = [
    '$ilike',
    '$like',
    '$notilike',
    '$notlike',
  ]
  const excludeFromWrap = [
    '$bounds',
    '$contained',
    '$contains',
    '$in',
    '$nin',
    '$overlap',
  ]

  return Object
    .fromEntries(Object
      .entries(cleanQuery)
      .map(([key, value]) => {
        if (excludeFromWrap.includes(key)) return [key, value]

        if (isObjectOrArray(value)) {
          if (Array.isArray(value)) {
            return [key, value.map(wrapInPercent)]
          }
          if (value instanceof Date) return [key, value.toISOString()]
          return [key, wrapInPercent(value)]
        }

        if (!fieldsToWrap.includes(key) || value.startsWith('%') || value.endsWith('%')) {
          return [key, value]
        }

        return [key, `%${value}%`]
      }))
}

export function buildServerQuery(query) {
  // TODO: make it deep
  // remove undefined values to allow using `queryParam || undefined` shorthand
  const { $server, ...cleanQuery } = Object
    .fromEntries(Object
      .entries(query)
      .filter(([_, value]) => typeof value !== 'undefined'))

  return wrapInPercent({
    ...cleanQuery,
    ...($server || {}),
  })
}

const Query = {
  _find(list, params, _state) {
    if (isNumeric(params)) return list[parseInt(params, 10)]

    const query = (params || {}).query || {}

    const { $fetch, ...preQuery } = query
    const { $skip, $limit, $sort, $select, ...querySift } = preQuery

    let values = Object
      .values(list)
      .filter(sift(querySift, {
        operations: customExpressions,
      }))

    if ($sort) {
      values.sort(typeof $sort === 'function' ? $sort : sorter($sort))
    }

    if ($skip) {
      values = values.slice($skip)
    }

    if (typeof $limit !== 'undefined') {
      values = values.slice(0, $limit)
    }

    if ($select) {
      values = values.map(value => _.pick(value, ...$select))
    }

    return values
  },

  find(list, params = {}, state) {
    if (Array.isArray(params)) {
      params = { query: { id: { $in: params } } }
    } else if (isObject(params) && !params.query) {
      params = { query: params }
    }

    // Call the internal find with query parameter that include pagination
    return this._find(list, params, state)
  },
}

export default (list, state) => {
  return (params, options) => {
    const isSingle = options && (options === true || options.isSingle)
    const result = Query.find(list, params, state)

    if (isSingle === true) {
      if (result && result.length) {
        return result[0]
      } else if (isObject(result)) {
        return result
      }

      return null
    }

    return result
  }
}
