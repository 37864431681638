import { factory } from '../../../utils'

export default factory(() => ({
  name: null,
  displayCode: null,
  vatCode: null,
  address: null,
  email: null,
  phone: null,
  representative: null,
}))
