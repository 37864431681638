<template>
  <div id="app">
    <AlertBar />
    <Modal v-if="modal.show" :modal="modal" />
    <CookieBar />
    <router-view />
  </div>
</template>

<script>
import './validationRules'
import { actions, getters } from './views/utils'
import AlertBar from './views/components/AlertBar'
import CookieBar from './views/components/CookieBar'
import { scrollToHash } from './router/smoothScroll'

export default {
  name: 'App',
  components: { AlertBar, CookieBar },
  data() {
    return {
      routeTimeout: null,
      routeTimeoutLoaded: null,
      timerInterval: null,
    }
  },
  computed: getters('hasAuthChecked', 'modal', 'referrers'),
  methods: {
    scrollToRouteHash(doWaitLoaded) {
      if (doWaitLoaded && !this.isLoaded) {
        this.routeTimeoutLoaded = setTimeout(() => this.scrollToRouteHash(true), 500)
        return
      }

      if (!this.$route.hash || !document.querySelector(this.$route.hash)) return

      scrollToHash(this.$route.hash)
    },
    ...actions(
      'CHECK_LOGIN',
      'CHECK_SCREEN_SIZE',
      'FETCH_STATE',
      'FETCH_SNIPPET',
      'INIT_STORE',
      'SYNC_GLOBAL',
      'SET_STORE',
      'SET_COOKIE',
    ),
  },
  watch: {
    $route(route) {
      if (!route.hash) return

      this.routeTimeout = setTimeout(() => this.scrollToRouteHash(false), 1000)
      this.routeTimeoutLoaded = setTimeout(() => this.scrollToRouteHash(true), 1000)
    },
    hasAuthChecked() {
      if (!this.isAuth) {
        this.LOAD(this.FETCH_STATE)
      }
    },
    isAuth(isAuth) {
      this.$nextTick(() => {
        this.LOAD(this.FETCH_STATE)
      })
    },
  },
  async created() {
    this.INIT_STORE(this)

    await this.$nextTick()

    this.FETCH_SNIPPET()

    this.LOAD(this.CHECK_LOGIN)
    this.SYNC_GLOBAL()
    this.CHECK_SCREEN_SIZE()
    this.$offEvents = await this.ON({
      resize: this.CHECK_SCREEN_SIZE,
    })

    if (this.referrers) {
      this.SET_STORE({ referrers: this.referrers })
    }

    // timeout for pre-render to take a snapshot only after state has been loaded
    setTimeout(() => {
      document.dispatchEvent(new Event('x-app-rendered'))
    }, 2000)
  },
  beforeDestroy() {
    this.$offEvents && this.$offEvents()

    clearInterval(this.timerInterval)
    clearTimeout(this.routeTimeout)
    clearTimeout(this.routeTimeoutLoaded)
  },
}
</script>

<style lang="scss">
#app {
  font-family: $font-body;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
  width: 100%;
}
</style>
