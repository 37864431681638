import { client } from './services'
import hooks from './client/hooks'
import manipulations from './manipulations'

const eventMutations = {
  created: 'add',
  patched: 'add',
  removed: 'remove',
  updated: 'add',
}

/*
* Syncs server data with internal store.
*/
export default function initSync(mods, store) {
  mods
    .filter(module_ => module_.auto.has('sync'))
    .forEach((module_) => {
      const service = client.getService(module_.serviceName)

      Object.keys(eventMutations).forEach((eventName) => {
        service.on(eventName, (result) => {
          // NOTE: BASIC sync version
          hooks.after.all.forEach((hook) => {
            hook({ result })
          })

          manipulations[eventMutations[eventName]](module_)(store, result)
        })
      })
    })
}
