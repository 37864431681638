import router from '@/router'
import { webOrigin } from '@/config'

const parseQuery = queryString => queryString
  .slice(queryString.startsWith('?') ? 1 : 0)
  .split('&')
  .map(pair => pair.split('=').map(decodeURIComponent))
  .reduce((accumulator, [key, value]) => {
    accumulator[key] = value || ''
    return accumulator
  }, {})

export default {
  auto: ['actions'],
  store: {
    actions: {
      async REDIRECT_TO_PAYSERA({ dispatch }, paymentId) {
        const payseraOrder = await dispatch('GET_PAYSERA', paymentId)

        redirectTo(payseraOrder.url)
      },
    },
    getters: {
      decodeResponse: () => response => {
        const decodedUrl = Buffer
          .from(response, 'base64')
          .toString('ascii')

        return parseQuery(decodedUrl)
      },
    },
  },
}

const redirectTo = (url) => {
  if (!url) return

  if (url.startsWith(webOrigin)) {
    router.push(url.replace(webOrigin, ''))
  } else {
    // eslint-disable-next-line xss/no-location-href-assign
    window.location.href = url
  }
}
