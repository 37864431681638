import { isObject } from 'views/utils'

export default {
  auto: ['actions'],
  store: {
    state: {
      list: [],
    },
    actions: {
      ERROR({ dispatch }, error) {
        if (!isObject(error)) error = new Error(error)

        // display error to user
        const message = error.message || error

        error.doIgnore = false

        error.ignore = () => {
          error.doIgnore = true
        }

        // NOTE: added a timeout for error alert, so it can be ignored
        setTimeout(() => {
          if (!error.doIgnore) dispatch('DANGER', message)
        }, 10)
      },
    },
  },
}
