import { factory } from '../utils'

export default {
  auto: '*',

  store: {
    getters: {
      projectDocumentFactory: factory(() => ({
        fileId: null,
        projectIdId: null,
        title: null,
      })),
    },
  },
}
