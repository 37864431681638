import Vue from 'vue'

const paginationFactory = (overrides = {}) => ({
  name: null,
  service: null,

  // current pagination index (current state)
  pageIndex: 0,

  // display records per page
  perPage: 12,

  // string filter
  hasSearch: true,
  search: '',
  searchIn: [],

  // categorical filtering
  isTabsMultiple: false,
  tabs: [],
  tabsIndexes: [],

  // fetch bounds
  bounds: {
    keys: [],
    constraints: [],
  },

  // field-level filter
  filters: [],
  filtersValues: {},

  hasSort: true,
  sortIndex: 0,
  sortOptions: [
    {
      labelKey: 'sort.new',
      sort: {
        id: -1,
      },
    },
    {
      labelKey: 'sort.old',
      sort: {
        id: 1,
      },
    },
  ],

  // query appended before being executed
  customQuery: {},

  // key to distinguish stored/non-stored pagination
  isStored: true,

  ...overrides,
})

export default {
  store: {
    state: {
      paginations: [],
    },
    actions: {
      CLEAR_PAGINATIONS({ commit }) {
        commit('CLEAR_PAGINATIONS')
      },
      PUSH_PAGINATION({ commit }, pagination) {
        commit('PUSH_PAGINATION', pagination)
      },
      PATCH_PAGINATION({ commit }, { pagination, ...patch }) {
        commit('PATCH_PAGINATION', { pagination, ...patch })
      },
      SET_PAGINATION_FILTER({ commit }, { pagination, label, value }) {
        commit('SET_PAGINATION_FILTER', { pagination, label, value })
      },
      RESET_PAGINATION_FILTER({ commit }, pagination) {
        commit('RESET_PAGINATION_FILTER', pagination)
      },
    },
    mutations: {
      CLEAR_PAGINATIONS(state) {
        state.paginations = []
      },
      SET_PAGINATION_FILTER(_, { pagination, label, value }) {
        if (typeof pagination.filters[label] === 'undefined') {
          Vue.set(pagination.filtersValues, label, value)
        } else {
          pagination.filters[label] = value
        }
      },
      RESET_PAGINATION_FILTER(_, pagination) {
        Vue.set(pagination, 'filtersValues', {})
      },
      PUSH_PAGINATION(state, pagination) {
        state.paginations.push(paginationFactory(pagination))
      },
      PATCH_PAGINATION(_, { pagination, ...patch }) {
        Object
          .keys(patch)
          .forEach((key) => {
            pagination[key] = patch[key]
          })
      },
    },
    getters: {
      getPagination: ({ paginations }) => paginationName =>
        paginations.find(pagination => pagination.name === paginationName),
    },
  },
}
