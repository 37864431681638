import { isObject } from '../../views/utils'

export default {
  store: {
    state: {
      // TODO: use config.js env
      env: window.location.host.includes('localhost') ? 'development' : 'production',
    },
    actions: {
      async FETCH_STATE({ dispatch, getters: { authUser, hasRole } }, { exclude = [] } = {}) {
        const permittedFetches = [
          'PROJECT',
          'PROJECT_POST',
        ]

        const isUser = hasRole('>=USER')

        if (isUser) {
          permittedFetches.push(
            'CONTRACT',
            'MESSAGE',
            // TODO: do not fetch PROJECT_POST
            'PROJECT_DOCUMENT',
            'PROJECT_POST',
            'PAYMENT',
            'RESERVATION',
            'USER',
          )
        }

        // TODO: test if can be removed
        // if (hasRole('>=SUPPORT')) {
        //   permittedFetches.push(
        //     'CONTACT_EMAIL',
        //     'DOC_TEMPLATE',
        //     'FILE',
        //     'PROMO',
        //     'STEP',
        //     'STEP_CONTRACT',
        //     'STEP_MESSAGE',
        //     'STEP_PAYMENT',
        //     'USER_STEP',
        //   )
        // }

        const fetchList = permittedFetches.reduce((accumulator, next) => ({
          ...accumulator,
          [next]: {
            ...isUser ? { userId: authUser.id } : {},
            $sort: { id: -1 },
          },
        }), {})

        // TODO: fix authed users fetching state 2 times - once as a guest, another time as a
        // logged in user
        return dispatch('LOAD', () => Promise.all(Object
          .keys(fetchList)
          .filter(serviceName => !(exclude.includes(serviceName)))
          .map(serviceName => dispatch(`FETCH_${serviceName}`, fetchList[serviceName]))))
      },
    },
    getters: {
      env: state => state.env,
      isDevelopment: (_, { env }) => env === 'development',

      splitIntoColumns: (_, { screenSizes }) => (entries, columnMap) => {
        const columnCountKey = Object.keys(columnMap).find(size => screenSizes.includes(size))
        const columnCount = columnMap[columnCountKey]
        return new Array(columnCount)
          .fill()
          .map((_, columnIndex) => entries
            .filter((_, index) => (index % columnCount) === columnIndex))
      },

      // TODO: rewrite in a similar fashion as splitIntoColumns
      splitIntoRows: (_, { screenSizes }) => (entries, columnMap) => {
        const columns = isObject(columnMap)
          ? columnMap[Object.keys(columnMap).find(size => screenSizes.includes(size))]
          : columnMap

        // support for objects
        const keys = isObject(entries) ? Object.keys(entries) : null
        const iterable = (keys || entries)
        const rows = new Array(Math.ceil(iterable.length / columns))
          .fill()
          .map(() => [])

        iterable.forEach((entry, index) => {
          const row = rows[Math.floor(index / columns)]
          if (keys) {
            row[entry] = entries[entry]
          } else {
            row.push(entry)
          }
        })

        return rows
      },
    },
  },
}
