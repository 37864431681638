import { factory } from '../utils'

export default {
  auto: '*',

  store: {
    getters: {
      messageFactory: factory(() => ({
        authorUserId: null,
        userId: null,
        reservationId: null,
        stepMessageId: null,
        subject: null,
        text: null,
        textHtml: null,

        isRaw: false,
      })),
    },
  },
}
