export const fallback = (string, fallbackValue = '-') => string || fallbackValue

export function commaDecimal(number, decimalPlaces = 2) {
  if (typeof number !== 'number') return ''

  return number.toFixed(decimalPlaces).replace('.', ',')
}

export function capitalize(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export function currency(number, symbol = '€', places = 2) {
  return money(number, places).replace(/(-?)(.*)/, `$1$2 ${symbol}`)
}

export const date = (() => {
  const months = [
    'Sausio',
    'Vasario',
    'Kovo',
    'Balandžio',
    'Gegužės',
    'Birželio',
    'Liepos',
    'Rugpjūčio',
    'Rugsėjo',
    'Spalio',
    'Lapkričio',
    'Gruodžio',
  ]

  return function date(dateObject, doShowYear) {
    const year = dateObject.getFullYear()

    if (typeof doShowYear === 'undefined') {
      doShowYear = year !== new Date().getFullYear()
    }

    return `${months[dateObject.getMonth()]} ${dateObject.getDate()} d.` +
      (doShowYear
        ? ', ' + year
        : '')
  }
})()

export const dateTime = (() => {
  function pad(string, length = 2, char = '0') {
    if (typeof string !== 'string') {
      string = string.toString()
    }

    const padLength = Math.max(length - string.length, 0)

    return padLength
      ? `${char.repeat(padLength)}${string}`
      : string
  }

  return function dateTime(dateObject) {
    if (!dateObject) return

    const dateString = date(dateObject)
    const timeString = `${pad(dateObject.getHours())}:${pad(dateObject.getMinutes())}`
    return `${dateString} ${timeString}`
  }
})()

export function isoDate(dateObject) {
  return dateObject.toISOString().slice(5, 10)
}

export function duration(diff) {
  if (diff < 3600) {
    return `${~~(diff / 60)} min.`
  } else if (diff < 86400) {
    return `${~~(diff / 3600)} val.`
  } else if (diff < 2592000) {
    return `${~~(diff / 86400)} d.`
  }

  return `${~~(diff / 2592000)} mėn.`
}

export function relativeTime(inputDate) {
  const diff = (Date.now() - new Date(inputDate).valueOf()) / 1000
  return diff >= 0
    ? `prieš ${duration(diff)}`
    : `už ${duration(diff * -1)}`
}

export const kw = (number, precision = 1) =>
  ['kW', 'MW', 'GW', 'TW'].reduce((accumulator, suffix) => {
    if (typeof accumulator !== 'number') return accumulator
    if (accumulator < 1000) return `${accumulator.toFixed(precision)}${suffix}`

    return accumulator / 1000
  }, number).replace('.', ',')

export const metricNumber = number => ['K', 'M', 'B']
  .reduce((accumulator, letter) => parseInt(accumulator, 10) > 1000
    ? Math.round(parseInt(accumulator, 10) / 1000) + letter
    : accumulator,
  number)

export const money = (number, places = 2) => {
  const decimalSplit = number.toFixed(places).split('.')
  const isNegative = number < 0
  const out = decimalSplit[0]
    .replace('-', '')
    // comma separated
    // .split('')
    // .reverse()
    // .map((char, index, arr) =>
    //   ((index + 1) % 3 === 0 && arr.length !== index + 1) ? ',' + char : char)
    // .reverse()
    // .join('')

  const decimal = decimalSplit[1] ? `${out},${decimalSplit[1]}` : out

  return isNegative ? '-' + decimal : decimal
}

export const name = (user) => {
  if (!user) return ''

  return user.companyName
    ? user.companyName
    : `${user.firstName || ''} ${user.lastName || ''}`.trim()
}

export const nameInitial = (user) => {
  if (!user) return ''
  if (user.companyName) return user.companyName

  const nameInitial = user.firstName
    ? `${user.firstName.slice(0, 1)}.`
    : ''

  return `${nameInitial} ${user.lastName || ''}`.trim()
}

export const percent = number => commaDecimal(number) + '%'
