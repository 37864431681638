import { getterFinder, getterOptions } from '../../utils'
import { prop } from 'rambda'

export const store = {
  state: {
    projectStatuses: [
      {
        label: 'Ruošiama',
        // shortLabel: 'Ruošiama',
        value: 'PLANNING',
      },
      {
        label: 'Priimamos rezervacijos',
        // shortLabel: 'Aktyvi',
        value: 'ACTIVE',
      },
      {
        label: 'Pilnai finansuotas',
        // shortLabel: 'Pilnai finansuotas',
        value: 'FUNDED',
      },
      {
        label: 'Statoma',
        // shortLabel: 'Statoma',
        value: 'CONSTRUCTION',
      },
      {
        label: 'Pastatyta',
        // shortLabel: 'Pastatyta',
        value: 'BUILT',
      },
    ],
  },
  getters: {
    projectStatuses: prop('projectStatuses'),
    projectStatusOptions: getterOptions('projectStatuses'),
    getProjectStatus: getterFinder('projectStatuses'),
  },
}
