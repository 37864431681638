import { factory } from '../utils'

const userContractFields = [
  'email',
  'firstName',
  'lastName',
  'address',
  'city',
  'postCode',
  'country',
  'personCode',
  'phone',
]

export default {
  auto: '*',

  store: {
    getters: {
      userFactory: factory(() => ({
        email: null,
        password: null,
        firstName: null,
        lastName: null,
        address: null,
        country: 'LT',
        phone: null,
        personCode: null,
        role: 'USER',
        hasAcceptedPolicy: false,
        hasSubscribed: false,
        isCompany: false,
        companyName: null,
        companyCode: null,
        companyVatCode: null,
        companyRepresentativeRole: null,
        iban: null,
      })),

      hasSufficientProfile: (_, { isAuth, authUser }) => isAuth &&
        userContractFields.every(key => authUser[key]),
    },
  },
}
