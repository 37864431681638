import client from './client'
import { isError } from 'views/utils'
import { jwtName } from 'config'
import modules from './modules'

/*
* Global data collector.
*/
function parseResponse(response) {
  if (typeof response === 'undefined') throw new Error('Undefined response')

  if (Array.isArray(response)) return response

  if (isError(response)) return new Error(response)

  return response
}

function reloadPage() {
  localStorage.setItem('latestErrorRefresh', Date.now())
  location.reload()
}

/*
* Global error handler.
*/
function handleError(error) {
  if (error && error.message) {
    if (error.name === 'NotAuthenticated') {
      delete localStorage[jwtName]
      return
    }

    if (error.message.includes('api/authentication')) {
      const latestErrorRefresh = localStorage.latestErrorRefresh

      if (!latestErrorRefresh) reloadPage()
      const tenSecondsAgo = (() => {
        const date = new Date()
        date.setSeconds(date.getSeconds() - 10)
        return date.getTime()
      })()

      if (tenSecondsAgo > latestErrorRefresh) {
        reloadPage()
      }
    }

    throw error
  }

  throw new Error(error)
}

export const services = Object.fromEntries(modules
  .map(({ serviceName }) => {
    if (!serviceName) return

    const service = client.getService(serviceName)

    const methods = {
      create: data => service.create(data),
      get: id => service.get(id),
      find: query => service.find({ query }),
      remove: (id, params) => service.remove(id, params),
      update: (id, data, params) => service.update(id, data, params),
      patch: (id, data, params) => service.patch(id, data, params),
    }

    return [
      serviceName,
      Object
        .keys(methods)
        .reduce((accumulator_, methodKey) => {
          accumulator_[methodKey] = async (a, b, c) => {
            try {
              const response = await methods[methodKey](a, b, c)
              return parseResponse(response)
            } catch (error) {
              return handleError(error)
            }
          }

          return accumulator_
        }, {}),
    ]
  })
  .filter(Boolean))

export { client }
