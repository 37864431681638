import { allPass, is, startsWith, test } from 'rambda'
import { castArray, clone, getUniqueArray, isError, isNumeric } from 'views/utils'
import { buildServerQuery } from 'views/utils/query'
import deepEquals from 'fast-deep-equal'
import { hashPagination } from './utils'
import initSync from './sync'
import { isProduction } from 'config'
import manipulations from './manipulations'
import modules from './modules'
import mutations from './mutations'
import { services } from './services'

const inject = (storeModule, manipulationKey) => ({ commit, getters }) => async (response) => {
  if (isError(response)) throw new Error(response)

  const results = getResults(response)

  if (storeModule.auto.has('mutations') && results) {
    manipulations[manipulationKey](storeModule)({ commit, getters }, results)
  }

  return response
}

const actions = {
  async CLEAR_STATE({ commit }) {
    Object
      .keys(mutations)
      .filter(mutationName => mutationName.startsWith('CLEAR_'))
      .forEach(commit)
  },
  SYNC_GLOBAL(store) {
    initSync(modules, store)
  },
}

const displayError = (() => {
  const isFailedGetRequest = allPass([
    is(String),
    startsWith('Timeout of'),
    test(/(find|get) on/),
  ])

  return storeModule => store => (error) => {
    const message = error && error.message

    // do not recurisively throw errors for errors
    if ((!storeModule ||
      !storeModule.storeSuffix ||
      storeModule.storeSuffix !== 'ERROR') &&
      (error && message.startsWith('Unexpected token'))) {
      error.message = 'Nepavyksta prisijungti prie serverio'
    }

    if (!isProduction || !isFailedGetRequest(message)) {
      store.dispatch('ERROR', error)
    }

    return Promise.reject(error)
  }
})()

const getResults = (response) => {
  if (!response) return null
  if (response.data) return castArray(response.data)
  return castArray(response)
}

const attempt = (performRequest, handlError, attemptsRemaining) => async (store, data) => {
  try {
    return await performRequest(store, data)
  } catch (error) {
    if (--attemptsRemaining > 0) {
      return attempt(performRequest, handlError, attemptsRemaining)(store, data)
    }

    return handlError(store)(error)
  }
}

// TODO: simplify
// eslint-disable-next-line sonarjs/cognitive-complexity
Object.values(modules).forEach((storeModule) => {
  if (!storeModule.auto.has('actions')) return

  const service = services[storeModule.serviceName]
  const addSuffix = actionName => `${actionName}_${storeModule.storeSuffix}`
  const addEntries = inject(storeModule, 'add')
  const addEntriesDirectly = store => response => addEntries(store, false)(response)
  const removeEntries = inject(storeModule, 'remove')
  const errorHandler = displayError(storeModule)
  const queryFetches = []

  // TODO: migrate to fully async/await
  /* eslint-disable promise/prefer-await-to-then */
  const actionsTemplate = {
    CREATE: (store, data) => service
      .create(data)
      .then(addEntries(store))
      .catch(errorHandler(store)),

    FIND_RAW: attempt(
      (store, query) => service
        .find(query)
        .then(addEntriesDirectly(store)),
      errorHandler,
      2,
    ),

    FIND: ({ dispatch }, query) =>
      dispatch(addSuffix('FIND_RAW'), query)
        .then(getResults),

    GET: attempt(
      (store, id) => service
        .get(id)
        .then(addEntriesDirectly(store)),
      errorHandler,
      2,
    ),

    // similar to GET/FIND but with checking if it's already fetched
    FETCH: async ({ commit, dispatch }, query = {}) => {
      // const storeEntries = stateList(state[storeModule.storeKey])

      // GET of one entry by ID
      if (Array.isArray(query)) {
        if (!query.length) return []

        query = {
          id: {
            $in: getUniqueArray(query),
          },
        }
      } else if (isNumeric(query)) {
        const id = parseInt(query, 10)

        // TODO: re-enable GET requests when they have joins
        // return dispatch(addSuffix('GET'), id)

        return dispatch(addSuffix('FIND_RAW'), { id })

        // TODO: work out caching for GET requests
        // NOTE:: we cannot use single store check since these do not show if the record had
        // its relationships loaded from the back-end
        // return storeEntries[id]
        //   ? Promise.resolve(storeEntries[id])
        //   : dispatch(addSuffix('GET'), parseInt(query, 10))
      }

      // FIND by query
      const refetchTime = Date.now() - storeModule.cacheFor
      const { $isForced, ...safeQuery } = query
      const serverQuery = buildServerQuery(safeQuery)
      const { $skip, ...queryWithoutPagination } = serverQuery
      const paginationHash = hashPagination(serverQuery)
      const recordedFetch = queryFetches
        .find(recordedFetch => deepEquals(recordedFetch.query, queryWithoutPagination))

      if (recordedFetch) {
        if (!$isForced && recordedFetch.fetchedAt[paginationHash] > refetchTime) {
          return []
        }

        recordedFetch.fetchedAt[paginationHash] = Date.now()
      } else {
        queryFetches.push({
          query: clone(queryWithoutPagination),
          fetchedAt: {
            [paginationHash]: Date.now(),
          },
        })
      }

      const result = await dispatch(addSuffix('FIND_RAW'), serverQuery)

      if (!Array.isArray(result.data)) return result

      const { total, skip } = result
      if (storeModule.auto.has('mutations')) {
        commit(addSuffix('ADD_PAGE_OBJECT'), {
          query: safeQuery,
          records: result.data,
          skip,
          total,
        })
      }
      // const queryHash = hashQuery({ ...query, $skip: skip })

      return result
    },

    DELETE: (store, { id }) => service
      .remove(id)
      .then(removeEntries(store))
      .catch(errorHandler(store)),

    UPDATE: (store, { id, ...data }) => service
      .update(id, data)
      .then(addEntries(store))
      .catch(errorHandler(store)),

    PATCH_RAW: (store, { id, ...data }) => service
      .patch(id, data),

    PATCH: (store, idData) => store
      .dispatch(addSuffix('PATCH_RAW'), idData)
      .then(addEntries(store))
      .catch(errorHandler(store)),
  }
  /* eslint-enable promise/prefer-await-to-then */

  const storeModuleActions = Object
    .fromEntries(Object
      .entries(actionsTemplate)
      .map(([actionName, action]) => [addSuffix(actionName), action]))

  Object.assign(actions, storeModuleActions)
})

export default actions
