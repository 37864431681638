import { getterFinder, getterOptions } from '../utils'
import { PAYMENT_TYPES } from '@/store/consts'
import { propEq } from 'rambda'

export default {
  auto: '*',
  hasJoins: true,

  store: {
    state: {
      types: [
        {
          label: 'Rezervacinis',
          value: PAYMENT_TYPES.RESERVATION,
        },
        {
          label: 'Avansinis',
          value: PAYMENT_TYPES.ADVANCE,
        },
        {
          label: 'Galutinis',
          value: PAYMENT_TYPES.MAIN,
        },
        {
          label: 'Priežiūros',
          value: PAYMENT_TYPES.MAINTENANCE,
        },
      ],
      // NOTE: deliberate order of keys
      statuses: [
        {
          label: 'Grąžintas',
          value: 'refundedAt',
          theme: 'danger',
        },
        {
          label: 'Apmokėtas',
          value: 'paidAt',
          theme: 'success',
        },
        {
          label: 'Atšauktas',
          value: 'cancelledAt',
          theme: 'danger',
        },
        {
          label: 'Bandytas ir nepavykęs',
          value: 'failedAt',
          theme: 'danger',
        },
        {
          label: 'Laukiamas banko patvirtinimas',
          value: 'acceptedAt',
          theme: 'info',
        },
        {
          label: 'Laukiamas',
          value: 'createdAt',
          theme: 'warning',
        },
      ],
    },

    getters: {
      paymentStatuses: state => state.statuses,
      getPaymentStatus: ({ statuses }) => (payment) => {
        const now = Date.now()
        const currentStatus = statuses
          .find(status => payment[status.value])

        if (currentStatus.value === 'createdAt' && payment.deadlineAt.getTime() > now) {
          return {
            label: 'Laukiamas',
            theme: 'warning',
          }
        }

        return currentStatus
      },
      paymentStatusOptions: getterOptions('paymentStatuses'),

      paymentTypes: state => state.types,
      getPaymentType: getterFinder('paymentTypes'),
      getPaymentTypeLabel: state => paymentType => state.types
        .find(propEq('value', paymentType))
        .label,
      paymentTypeOptions: getterOptions('paymentTypes'),
    },
  },
}
