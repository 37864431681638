import './extensions'
import 'views/style/_global.scss'
import * as components from './views/components/globals'
import * as filters from './views/utils/filters'
import { ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate'
import {
  analyticsId,
  facebookId,
  isDeployedProduction,
} from './config'
import App from './App'
import Router from 'vue-router'
import Vue from 'vue'
import VueAnalytics from 'vue-analytics'
import VueCookies from 'vue-cookies'
import VueFacebookPixel from 'vue-analytics-facebook-pixel'
import VueHead from 'vue-head'
import VueLocalStorage from 'vue-localstorage'
import VueObserveVisibility from 'vue-observe-visibility'
import { _ } from '@feathersjs/commons'
import { attachBugsnag } from './bugsnag'
import router from './router'
import store from './store'

Vue.config.productionTip = false

// VeeValidate
Vue.component('ValidationObserver', ValidationObserver)
Vue.component('ValidationProvider', ValidationProvider)
setInteractionMode('eager')

// global filters
_.each(filters, (filter, name) => Vue.filter(name, filter))

// global components
_.each(components, (component, tag) => Vue.component(tag, component))

Vue.use(Router)
Vue.use(VueLocalStorage)
Vue.use(VueObserveVisibility)
Vue.use(VueHead)

if (analyticsId) {
  Vue.use(VueAnalytics, {
    id: analyticsId,
    disableScriptLoader: !isDeployedProduction,
    router,
  })
}

Vue.use(VueFacebookPixel)
if (isDeployedProduction) {
  Vue.analytics.fbq.init(facebookId)
  Vue.analytics.fbq.event('PageView')
}

Vue.use(VueCookies)

attachBugsnag()

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
