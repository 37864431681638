import { factory } from '../utils'

const modalFactory = factory(() => ({
  actions: [],
  icon: null,
  name: '',
  show: false,
  text: '',
  theme: null,
  typeConfirm: null,
  template: null,
}))()

export default {
  store: {
    state: {
      modal: modalFactory(),
    },
    actions: {
      PROMPT({ dispatch }, modal) {
        dispatch('SHOW_MODAL', {
          actions: [
            {
              action: 'close',
              type: 'close',
              theme: 'neutral',
              label: 'Ne',
            },
            {
              action: 'confirm',
              type: 'confirm',
              theme: 'danger',
              label: 'Taip',
            },
          ],
          ...modal,
        })
      },
      SHOW_MODAL: ({ commit }, modal) => {
        commit('ADD_MODAL', { show: true, ...modal })
      },
      CLOSE_MODAL({ commit }) {
        commit('CLOSE_MODAL')
      },
    },
    mutations: {
      ADD_MODAL(state, modal) {
        state.modal = modalFactory(modal)
      },
      CLOSE_MODAL(state) {
        state.modal.show = false
      },
    },
    getters: {
      modal: state => state.modal,
    },
  },
}
