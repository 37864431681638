import Vue from 'vue'
import Vuex from 'vuex'
import actions from './actions'
import getters from './getters'
import modules from './modules'
import mutations from './mutations'

Vue.use(Vuex)

export default new Vuex.Store({
  actions,
  getters,
  mutations,
  modules: Object.fromEntries(modules
    .map(storeModule => [storeModule.storeKey, storeModule.store])),
  strict: process.env.NODE_ENV !== 'production',
})
