import { castArray, getUniqueArray } from 'views/utils'
import { uniqueBy } from './utils'

export default (() => {
  function sumNormalized(entries) {
    const keys = entries.map(entry => entry[0])
    const uniqueKeys = getUniqueArray(keys)

    return uniqueKeys
      .map(uniqueKey => [
        uniqueKey,
        uniqueBy(entries
          .filter(([key]) => key === uniqueKey)
          .flatMap(entry => entry[1]), 'id'),
      ])
  }

  function normalize(storeKey, joins, recordsOrRecord) {
    if (!joins || !Object.keys(joins).length) return [[storeKey, recordsOrRecord]]

    const joinsKeys = Object.keys(joins)
    const records = castArray(recordsOrRecord)
    const trimmedMainRecords = records
      .map(record => Object
        .fromEntries(Object
          .entries(record)
          .filter(([key]) => !joinsKeys.includes(key))))

    const recordsJoined = joinsKeys
      .flatMap(key => records
        .filter(record => Array.isArray(record[key]) ? record[key].length : record[key])
        .flatMap(record =>
          normalize(joins[key].storeKey, joins[key].children, castArray(record[key]))))

    return [
      [storeKey, trimmedMainRecords],
      ...recordsJoined,
    ]
  }

  const getActionName = suffix => `ADD_${suffix}`

  return {
    add: (storeModule) => {
      const { hasJoins, hasJoinsFixed, storeKey, storeSuffix } = storeModule

      return ({ commit, getters }, results) => {
        if (!hasJoins) {
          return commit(getActionName(storeSuffix), results)
        }

        // NOTE: important that joins are listed in their dependency order.
        // First should come joins that are depended by following joins.
        if (!hasJoinsFixed && !storeModule.joins) storeModule.joins = {}

        const recordsByStoreModule = sumNormalized(normalize(storeKey, storeModule.joins, results))

        recordsByStoreModule
          .forEach(([moduleStoreKey, records]) => {
            commit(getActionName(getters[`${moduleStoreKey}Suffix`]), records)
          })
      }
    },

    remove: ({ storeSuffix }) => ({ commit }, results) =>
      commit(`REMOVE_${storeSuffix}`, results),
  }
})()
