import { always, concat, equals, evolve, filter, find, ifElse, isEmpty, isNil, join, map, merge, pick, pipe, pluck, prop, propEq, reject, values, when } from 'rambda'
import { factory, getterFinder } from '../utils'
import { isPlainObject } from '@/views/utils/guards'
import sift from 'sift'

const valuesDeep = pipe(
  values,
  map(when(isPlainObject, value => valuesDeep(value))),
)

export default {
  auto: '*',
  store: {
    getters: {
      stepFactory: factory(() => ({
        triggers: [],
        cancellations: [],
        outcomes: [],
        comment: null,
      })),

      getEventName: () => triggerConcat => ({
        'contract.cancelled': 'Atšaukiama sutartis',
        'contract.cancelledUnilateral': 'Atšaukiama sutartis (vienašališkai)',
        'contract.created': 'Sukuriama sutartis',
        'contract.amended': 'Sukuriamas sutarties priedas',
        'contract.deleted': 'Ištrinama sutartis',
        'contract.signed': 'Pasirašoma sutartis',
        'contract.visible': 'Sukuriama matoma sutartis',
        'message.created': 'Išsiunčiama žinutė',
        'payment.cancelled': 'Atšaukiamas mokėjimas',
        'payment.created': 'Sukuriamas mokėjimas',
        'payment.deleted': 'Ištrinamas mokėjimas',
        'payment.paid': 'Atliekamas mokėjimas',
        'reservation.amendedReservationSigned':
          'Pakeičiamas užsakymas po rezervacinės sutarties pasirašymo',
        'project.built': 'Pastotomas projektas',
        'reservation.created': 'Sukuriama rezervacija',
      }[triggerConcat]),

      eventKinds: () => [
        {
          icon: 'message',
          label: 'Žinutė',
          value: 'message',
          theme: 'info',
        },
        {
          icon: 'file',
          label: 'Sutartis',
          value: 'contract',
          theme: 'main',
        },
        {
          icon: 'bill',
          label: 'Mokėjimas',
          value: 'payment',
          theme: 'success',
        },
        {
          icon: 'bolt',
          label: 'Rezervacija',
          value: 'reservation',
          theme: 'danger',
        },
        {
          icon: 'bolt',
          label: 'Projektas',
          value: 'project',
          theme: 'danger',
        },
      ],

      getEventKind: getterFinder('eventKinds'),

      ...(() => {
        const doesMatch = (() => {
          const doesMatchQuery = (query) => {
            const siftQuery = sift(query)

            return (object) => {
              if (!query) return true

              return siftQuery(object)
            }
          }

          // TODO: support matching by reservation
          return (eventMatch, project, reservation) => {
            const bodyQuery = reject(isNil, eventMatch)
            const doesMatchBodyQuery = doesMatchQuery(bodyQuery)

            return event =>
              doesMatchBodyQuery(event) &&
              doesMatchQuery(event.projectQuery)(project) &&
              doesMatchQuery(event.reservationQuery)(reservation)
          }
        })()

        const evolveStepEvents = (() => {
          const stepEventKeys = [
            'cancellations',
            'outcomes',
            'triggers',
          ]

          return transform => evolve(Object
            .fromEntries(stepEventKeys.map(key => [key, transform])))
        })()

        const getMatchingStepEvent = typeKey => (_, { query }) =>
          (eventMatch, project, reservation) =>
            query('step')
              .flatMap(prop(typeKey))
              .find(doesMatch(eventMatch, project, reservation))

        const getMatchingStepsByEvent = typeKey => (_, { query }) =>
          (eventMatch, project, reservation) => query('step')
            .filter(step => step[typeKey]
              .some(doesMatch(eventMatch, project)))
            .map(evolveStepEvents(filter(doesMatch({}, project, reservation))))

        return {
          getMatchingStepOutcome: getMatchingStepEvent('outcomes'),
          getMatchingStepsByTrigger: getMatchingStepsByEvent('triggers'),
        }
      })(),

      serializeProjectQuery: (_, { query, projectStepBranchOptions }) => (projectQuery) => {
        if (!projectQuery) return null

        const queryProjects = ids => query('project', ids)

        const toStepBranchOptions = map(stepBranch => find(
          propEq('value', stepBranch),
          projectStepBranchOptions,
        ))

        const toProjectNames = pipe(
          queryProjects,
          pluck('name'),
          join(', '),
        )

        const toBranchNames = pipe(
          toStepBranchOptions,
          pluck('label'),
          map(label => `Šaka "${label}"`),
          join(', '),
        )

        return pipe(
          evolve({
            id: {
              $in: toProjectNames,
              $nin: pipe(
                toProjectNames,
                concat('Išskyrus '),
              ),
            },
            stepBranch: {
              $in: toBranchNames,
            },
          }),
          valuesDeep,
          join('; '),
        )(projectQuery)
      },

      serializeReservationQuery: () => ifElse(
        Boolean,
        pipe(
          evolve({
            hasSkippedReservation: ifElse(
              equals(true),
              always('pasirinkusiems iškart mokėti avansą'),
              always('pasirinkusiems atlikti rezervacinį mokestį'),
            ),
          }),
          valuesDeep,
          join('; '),
        ),
        always(null),
      ),

      serializeStepEventConditions: (_, { serializeProjectQuery, serializeReservationQuery }) =>
        pipe(
          pick(['projectQuery', 'reservationQuery']),
          merge({
            projectQuery: null,
            reservationQuery: null,
          }),
          evolve({
            projectQuery: serializeProjectQuery,
            reservationQuery: serializeReservationQuery,
          }),
          valuesDeep,
          filter(Boolean),
          ifElse(
            isEmpty,
            always('Visi'),
            join(', '),
          ),
        ),
    },

    actions: {
      LOAD_STEP_TREE({ dispatch }) {
        return dispatch('LOAD', () => Promise.all([
          dispatch('FETCH_STEP'),
          dispatch('FETCH_STEP_CONTRACT'),
          dispatch('FETCH_STEP_MESSAGE'),
          dispatch('FETCH_STEP_PAYMENT'),
        ]))
      },
    },
  },
}
