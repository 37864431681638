<template>
  <Sect v-if="project" id="Calculator" class="Calculator">
    <Wrap class="lg">
      <h2>{{ titleToDisplay }}</h2>
      <Row class="content">
        <Column class="sliderSide" :lg="8">
          <div class="sub">
            <p v-for="(paragraph, index) in paragraphsToDisplay" :key="index">
              {{ paragraph }}
            </p>
            <small>
              {{ subTextToDisplay }}
            </small>
          </div>
          <Slider
            v-if="!disabled"
            :format="tooltipFormat"
            :max="maxAmount"
            :min="minAmount"
            :processStyle="{
              backgroundColor: '#4b6ce6',
            }"
            :railStyle="{
              height: '10px',
            }"
            :tooltipPosition="tooltipPosition"
            :tooltipStyle="{
              background: '#4b6ce6',
              borderColor: '#4b6ce6',
              borderRadius: '60px',
              cursor: 'pointer',
              padding: '2rem 1.5rem',
              margin: '0 0 2rem 0',
              textAlign: 'center',
              fontSize: '1.2rem',
              fontWeight: 'bold',
              whiteSpace: 'pre',
            }"
            :value="value"
            @update:value="$emit('update:value', $event)" />
          <div v-if="isDesktop" class="disclaimer">
            <p v-html="disclaimersToDisplay" />
          </div>
        </Column>
        <Column :lg="4">
          <div class="calculations">
            <div
              v-for="(calculation, index) in calculatedWithLabels"
              :key="index">
              <div class="calculation">
                <div class="label">
                  {{ calculation[0] }}
                </div>
                <div class="value">
                  {{ calculation[1] }}
                </div>
              </div>
            </div>
            <Field
              v-if="!isBusinessMode"
              :disabled="isBusinessMode"
              label="Skaičiuoti su parama"
              type="checkbox"
              :value.sync="countApva" />
            <!-- <Route v-if="doShowCta" to="SolarPlants">
              <Btn>Noriu rezervuoti</Btn>
            </Route> -->
          </div>
          <!-- <SimpleTable :cols="[8, 4]" :rows="calculated" /> -->
        </Column>
      </Row>
      <slot />
      <div v-if="!isDesktop" class="disclaimer">
        <p v-html="disclaimersToDisplay" />
      </div>
    </Wrap>
  </Sect>
</template>

<script>
// import SimpleTable from './SimpleTable'
import { getters } from 'views/utils'

export default {
  components: {
    // SimpleTable,
    Slider: () => import(/* webpackChunkName: "slider" */ './Slider'),
  },
  props: {
    project: Object,
    paragraphs: {
      type: Array,
    },
    title: {
      type: String,
    },
    value: Number,
    disabled: {
      type: Boolean,
      default: false,
    },
    doShowCta: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      countApva: false,
      // inflation: 1.03,
      // inflationOm: 1.02,
      inflation: 1,
      inflationOm: 1,
      minKwh: 1,
      minAmount: 86,
      maxAmountLimit: 858,
      tooltipFormat: kwh => [
        `${kwh} kWh/mėn.`,
        `${Math.round(kwh * this.project.priceKwh)} EUR/mėn.`,
      ].join('\n'),
    }
  },
  computed: {
    titleToDisplay() {
      // title from prop is priority
      if (this.title) return this.title

      return this.isBusinessMode
        ? 'Sužinokite, kiek jūsų verslas gali sutaupyti įsigijęs saulės parko dalį'
        : 'Sužinokite, kiek galite sutaupyti įsigiję saulės parko dalį'
    },
    disclaimersToDisplay() {
      // eslint-disable-next-line xss/no-mixed-html
      return [
        ...this.countApva ? ['Paramos dydis vertinamas 323 Eur už 1 kW saulės elektrinės įrengtosios galios.<br>'] : [],
        'Investicijos atsipirkimo laikotarpio skaičiavimai yra preliminarūs.<br>',
        `Vidutiniai taupymai paskaičiuoti remiantis elektros kaina (${(this.project.priceKwh * 100).toFixed(1)} ct/kW).<br>`,
        'Elektrinė bus įrengta naudojant patikimiausių pasaulinių kompanijų įrangą. Įrenginio dalies modulių efektyvumo garantija 80 proc. 25 m.',
      ].map((disclaimer, index) => `${'*'.repeat(index + 1)} ${disclaimer}`).join('')
    },
    paragraphsToDisplay() {
      // paragraphs from prop is priority
      if (this.paragraphs) return this.paragraphs

      return this.isBusinessMode
        ? [
          'Virtuali saulės energijos kainos skaičiuoklė – patogus ir greitas būdas sužinoti, kokios galios elektrinė reikalinga Jūsų verslui, kokios išlaidos laukia ir kada būtų galima tikėtis investicinės grąžos.',
          'Pasirinkite suvartojamos elektros energijos kiekį per mėnesį (kWh) ir saulės elektrinės skaičiuoklė pateiks Jums reikalingus duomenis.',
        ]
        : [
          'Virtuali saulės energijos kainos skaičiuoklė – patogus ir greitas būdas sužinoti, kokios galios elektrinė reikalinga Jūsų namams, kokios išlaidos Jūsų laukia ir kada būtų galima tikėtis investicinės grąžos.',
          'Pasirinkite suvartojamos elektros energijos kiekį per mėnesį (kWh) ir saulės elektrinės skaičiuoklė pateiks Jums reikalingus duomenis.',
        ]
    },
    subTextToDisplay() {
      return 'Rezervuojama galia nėra ribojama, norimą kW kiekį įveskite į laukelį esantį po skaičiuokle.'
    },
    averageAnnualConsumption() {
      return this.value * 12
    },
    averageAnnualProduction() {
      return this.averageAnnualConsumption
    },
    capacityRemaining() {
      return this.project.capacity - this.project.capacityUsed
    },
    capacityRemainingMin() {
      return this.capacityRemaining - this.minKwh
    },
    feeOnInstalledPower() {
      return this.pvCapacityPerPerson *
        this.project.feeMonthly * 12
    },
    yearlyInflations() {
      return this.getYearlyInflations(this.inflation)
    },
    yearlyOmInflations() {
      return this.getYearlyInflations(this.inflationOm)
    },
    yearlyElectricityPrices() {
      return this.yearlyInflations
        .map(yearlyInflation => yearlyInflation * this.project.priceKwh)
    },
    yearlySavings() {
      return this.yearlyElectricityPrices
        .map(yearlyElectricityPrice => yearlyElectricityPrice * this.yearlyPower)
    },
    yearlyCosts() {
      const omAnnualInclVat = this.project.omAnnual * (1 + this.project.vatRate)

      return this
        .yearlyOmInflations
        .map(yearlyInflation =>
          this.pvCapacityPerPerson *
          (yearlyInflation * omAnnualInclVat + (this.project.feeMonthly * 12)))
    },
    yearlyNetSavings() {
      return this
        .yearlySavings
        .map((yearlySavings, index) => yearlySavings - this.yearlyCosts[index])
    },
    totalNetSavings() {
      return this
        .yearlyNetSavings
        .reduce((sum, yearlyNetSavings) => sum + yearlyNetSavings, 0) - this.stationCostSubsidized
    },
    netSavingsAverage() {
      const totalYearlySavings = this.yearlyNetSavings
        .reduce((sum, yearlyNetSavings) => sum + yearlyNetSavings, 0)

      return totalYearlySavings / this.project.lifetimeYears
    },
    isCapacityRemainingMinSufficient() {
      return this.capacityRemainingMin > this.minKwh
    },
    isInvalidAmount() {
      return this.potentialRemainingAmount && this.potentialRemainingAmount < this.minKwh
    },
    isOverCapacity() {
      return this.potentialRemainingAmount < 0
    },
    maxAmount() {
      const remainingMonthlyConsumption = this.capacityRemaining * this.project.yieldAnnual / 12
      return Math.min(this.maxAmountLimit, ~~remainingMonthlyConsumption)
    },
    potentialRemainingAmount() {
      return Number((this.capacityRemaining - this.pvCapacityPerPerson).toFixed(1))
    },
    pvCapacityPerPerson() {
      return this.averageAnnualConsumption / this.project.yieldAnnual
    },
    savingsPerYear() {
      return this.averageAnnualConsumption * this.project.priceKwh
    },
    stationCost() {
      return this.pvCapacityPerPerson * this.project.price
    },
    stationCostSubsidized() {
      if (!this.countApva) return this.stationCost

      const subsidyTotal = this.pvCapacityPerPerson * this.project.subsidy
      return this.stationCost - subsidyTotal
    },
    paybackYears() {
      let sumRemaining = this.stationCostSubsidized
      const yearsWhole = this.yearlyNetSavings
        .findIndex((yearlyNetSavings) => {
          if (sumRemaining - yearlyNetSavings <= 0) return true

          sumRemaining -= yearlyNetSavings
        })

      const yearsFraction = sumRemaining / this.yearlyNetSavings[yearsWhole]

      return yearsWhole + yearsFraction
    },
    yearlyPower() {
      return this.averageAnnualConsumption
    },
    calculatedWithLabels() {
      const withApva = [
        'Rekomenduojama saulės elektrinės galia, kW',
        // [
        //   'Metinė elektros energijos gamyba, kWh/metus',
        //   this.yearlyPower.toFixed(),
        // ],
        'Įsigijimo kaina su parama*, €',
        'Investicijos atsipirkimo laikas, metai**',
        'Vidutinis metinis sutaupymas***, €/metus',
        'Sutaupymas per garantuotą elektrinės veikimo laikotarpį****, €',
      ]

      const withoutApva = [
        'Rekomenduojama saulės elektrinės galia, kW',
        'Įsigijimo kaina, €',
        'Investicijos atsipirkimo laikas, metai*',
        'Vidutinis metinis sutaupymas**, €/metus',
        'Sutaupymas per garantuotą elektrinės veikimo laikotarpį***, €',
      ]

      const mapLabels = (labels, values) => {
        return labels.map((label, index) => ([
          label,
          values[index],
        ]))
      }

      return this.countApva
        ? mapLabels(withApva, this.calculated)
        : mapLabels(withoutApva, this.calculated)
    },
    calculated() {
      return [
        this.$options.filters.commaDecimal(this.pvCapacityPerPerson),
        // [
        //   'Metinė elektros energijos gamyba, kWh/metus',
        //   this.yearlyPower.toFixed(),
        // ],
        this.stationCostSubsidized.toFixed(),
        this.$options.filters.commaDecimal(this.paybackYears, 1),
        this.netSavingsAverage.toFixed(),
        this.totalNetSavings.toFixed(),
      ]
    },
    tooltipPosition() {
      if (this.screenSizes.includes('xxl')) return 'top'

      const breakPercentage = 0.05
      const valuePercentage = this.value / this.maxValue

      if (valuePercentage < breakPercentage) return 'right'
      if (valuePercentage > 1 - breakPercentage) return 'left'
      return 'top'
    },
    ...getters('isDesktop', 'screenSizes', 'isBusinessMode'),
  },
  methods: {
    getYearlyInflations(inflationRate) {
      return new Array(this.project.lifetimeYears - 1)
        .fill()
        .reduce((inflations, _, year) => [
          ...inflations,
          inflations[inflations.length - 1] * inflationRate,
        ], [1])
    },
    setCapacity(capacity) {
      this.value = ~~((capacity * this.project.yieldAnnual) / 12)
    },
  },
  watch: {
    pvCapacityPerPerson: {
      immediate: true,
      handler(pvCapacity) {
        if (typeof pvCapacity !== 'number') return

        return this.$emit('pvCapacity', pvCapacity)
      },
    },
    isBusinessMode: {
      immediate: true,
      handler() {
        if (!this.isBusinessMode) {
          this.countApva = true
          return
        }

        this.countApva = false
      },
    },
  },
  mounted() {
    if (!this.isBusinessMode) this.countApva = true
  },
}
</script>

<style lang="scss">
.Sect.Calculator {
  // @include roundBottomOutside($red);
  // padding-bottom: 10rem;
  // padding-top: 0;
  @include lg {
    padding-top: 2rem;
  }

  .content {
    gap: 2rem;
    justify-content: space-between;
    @include lg {
      gap: unset;
    }
  }

  .sliderSide {
    padding: 0 2rem;
  }

  .vue-slider {
    margin: 15rem 3rem 5rem;
    @include lg {
      margin: 15rem 2rem 5rem;
    }
  }

  h2 {
    color: $black-bis;
    font-weight: $regular;
    margin-bottom: 2rem;
    // &.sub {
    //   font-size: $h3;
    //   margin-bottom: 5rem;
    // }
    @include lg {
      font-size: $h2;
      margin-bottom: 5rem;
    }
  }

  .calculations {
    background: $white;
    border-radius: $radius 0;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    height: 100%;
    padding: 3rem;

    .calculation {
      border-bottom: 2px solid $black-bis;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 1rem;

      .label {
        max-width: 50%;
        text-align: left;
      }

      .value {
        color: $blue;
        font-size: 2rem;
        font-weight: $bold;
        text-align: right;
      }

      // &:last-of-type {
      //   border-bottom: none;
      // }
    }
  }

  .invalid-capacity {
    span {
      color: $link-color;
      cursor: pointer;
      text-decoration: underline;
    }
  }

  .sub {
    font-size: $h4;
    font-weight: $regular;
    margin-bottom: 5rem;
    text-align: left;
    @include lg {
      font-size: $h3;
      margin-bottom: 4rem;
    }

    > p {
      margin-bottom: 1rem;
    }

    > small {
      font-size: $h5;
    }
  }

  .vue-slider-rail {
    background: $white;
    // border: 1px solid $white;
    outline: 2px solid $black-bis;
    outline-offset: 0.6rem;
  }

  .vue-slider-process {
    background-color: $blue;
  }

  .disclaimer {
    line-height: 1rem;
    margin-top: 1rem;
    @include lg {
      line-height: 1.5rem;
    }

    p {
      font-size: 0.6rem;
      font-weight: $regular;
      line-height: inherit;
      text-align: left;
      @include lg {
        font-size: 1rem;
      }
    }
  }

  .SimpleTable {
    @include lg {
      margin-bottom: 2rem;
    }

    .cell.neutral {
      font-size: $h4;
      padding: 0.5rem 1rem;
    }
  }
}
</style>
