<template>
  <div class="RowGrid" :style="style">
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    columns: { type: [Array, Number] },
  },
  computed: {
    style() {
      if (!this.columns) return

      const gridTemplateColumns = Array.isArray(this.columns)
        ? this.columns
          .map(column => typeof column === 'number' ? `${column}fr` : column)
          .join(' ')
        : `repeat(${this.columns}, 1fr)`

      return {
        gridTemplateColumns,
      }
    },
  },
}
</script>

<style lang="scss">
$_gutter: $grid-gutter-width;

.RowGrid {
  display: grid;
  grid-gap: $_gutter;
  grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
}
</style>
