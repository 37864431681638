<template functional>
  <div
    class="Column"
    :class="[$options.cols(props), data.class, data.staticClass]"
    :style="props.grow && { flexGrow: props.grow }">
    <slot />
  </div>
</template>

<script>
const sizes = ['xs', 'sm', 'md', 'lg', 'xl', 'xxl', 'xxxl']

const sizesWithDefaults = sizes.reduce((sizesProps, size) => {
  sizesProps[size] = {
    type: [String, Number],
    default: size === 'xs' ? 12 : null,
    validator: value => typeof value === 'number' || value === 'auto',
  }
  return sizesProps
}, {})

export default {
  props: {
    ...sizesWithDefaults,
    grow: Number,
  },
  cols(props) {
    return sizes
      .filter(size => props[size])
      .map(size => `col-${size}-${props[size]}`)
      .join(' ')
  },
}
</script>

<style lang="scss">
$_gutter: $grid-gutter-width;

@media (min-width: $res-sm) {
  // .col-sm {
  //   flex-basis: 0;
  //   flex-grow: 1;
  //   max-width: 100%;
  // }
  .col-sm-auto {
    flex: 0 0 auto;
    max-width: 100%;
    width: auto;
  }

  .col-sm-0 {
    display: none;
  }

  .col-sm-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }

  .col-sm-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-sm-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .col-sm-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }

  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-sm-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }

  .col-sm-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }

  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-sm-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }

  .col-sm-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }

  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media (min-width: $res-md) {
  // .col-md {
  //   flex-basis: 0;
  //   flex-grow: 1;
  //   max-width: 100%;
  // }
  .col-md-auto {
    flex: 0 0 auto;
    max-width: 100%;
    width: auto;
  }

  .col-md-0 {
    display: none;
  }

  .col-md-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }

  .col-md-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-md-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .col-md-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }

  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-md-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }

  .col-md-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }

  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-md-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }

  .col-md-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }

  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media (min-width: $res-lg) {
  // .col-lg {
  //   flex-basis: 0;
  //   flex-grow: 1;
  //   max-width: 100%;
  // }
  .col-lg-auto {
    flex: 0 0 auto;
    max-width: 100%;
    width: auto;
  }

  .col-lg-0 {
    display: none;
  }

  .col-lg-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }

  .col-lg-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-lg-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .col-lg-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }

  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-lg-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }

  .col-lg-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }

  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-lg-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }

  .col-lg-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }

  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media (min-width: $res-xl) {
  // .col-xl {
  //   flex-basis: 0;
  //   flex-grow: 1;
  //   max-width: 100%;
  // }
  .col-xl-auto {
    flex: 0 0 auto;
    max-width: 100%;
    width: auto;
  }

  .col-xl-0 {
    display: none;
  }

  .col-xl-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }

  .col-xl-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-xl-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .col-xl-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }

  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-xl-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }

  .col-xl-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }

  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-xl-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }

  .col-xl-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }

  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media (min-width: $res-xxl) {
  // .col-xxl {
  //   flex-basis: 0;
  //   flex-grow: 1;
  //   max-width: 100%;
  // }
  .col-xxl-auto {
    flex: 0 0 auto;
    max-width: 100%;
    width: auto;
  }

  .col-xxl-0 {
    display: none;
  }

  .col-xxl-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }

  .col-xxl-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .col-xxl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-xxl-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .col-xxl-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }

  .col-xxl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-xxl-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }

  .col-xxl-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }

  .col-xxl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-xxl-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }

  .col-xxl-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }

  .col-xxl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media (min-width: $res-xxxl) {
  // .col-xxxl {
  //   flex-basis: 0;
  //   flex-grow: 1;
  //   max-width: 100%;
  // }
  .col-xxxl-auto {
    flex: 0 0 auto;
    max-width: 100%;
    width: auto;
  }

  .col-xxxl-0 {
    display: none;
  }

  .col-xxxl-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }

  .col-xxxl-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .col-xxxl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-xxxl-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .col-xxxl-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }

  .col-xxxl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-xxxl-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }

  .col-xxxl-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }

  .col-xxxl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-xxxl-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }

  .col-xxxl-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }

  .col-xxxl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.Column {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
  padding-left: $_gutter;
  padding-right: $_gutter;
  position: relative;
  width: 100%;
}

.col-xs-auto {
  flex: 0 0 auto;
  max-width: 100%;
  width: auto;
}

.col-xs-0 {
  display: none;
}

.col-xs-1 {
  flex: 0 0 8.333333%;
  max-width: 8.333333%;
}

.col-xs-2 {
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
}

.col-xs-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-xs-4 {
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}

.col-xs-5 {
  flex: 0 0 41.666667%;
  max-width: 41.666667%;
}

.col-xs-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-xs-7 {
  flex: 0 0 58.333333%;
  max-width: 58.333333%;
}

.col-xs-8 {
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
}

.col-xs-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-xs-10 {
  flex: 0 0 83.333333%;
  max-width: 83.333333%;
}

.col-xs-11 {
  flex: 0 0 91.666667%;
  max-width: 91.666667%;
}

.col-xs-12 {
  flex: 0 0 100%;
  max-width: 100%;
}
</style>
