import { factory, stateOptions } from '../utils'

export default {
  auto: '*',
  store: {
    getters: {
      stepMessageFactory: factory(() => ({
        name: null,
        text: null,
        doNotifyAdmin: false,
        adminSubject: null,
      })),
      stepMessageOptions: stateOptions(),
    },
  },
}
