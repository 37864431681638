// export const INFLATION_ADJUSTMENT_TYPES = {
//   PROJECT: 'PROJECT',
//   RESERVATION: 'RESERVATION',
// }

export const store = {
  // state: {
  //   inflationAdjustmentTypes: [
  //     {
  //       label: 'Visi projekto užsakymai',
  //       value: INFLATION_ADJUSTMENT_TYPES.PROJECT,
  //     },
  //     {
  //       label: 'Individualiai kiekvienam užsakymui',
  //       value: INFLATION_ADJUSTMENT_TYPES.RESERVATION,
  //     },
  //   ],
  // },
  getters: {
    projectsMissingAdjustments: (_, { query }) =>
      query('project', { hasYearlyInflationAdjustments: false }),

    // inflationAdjustmentTypes: prop('inflationAdjustmentTypes'),
    // inflationAdjustmentTypeOptions: getterOptions('inflationAdjustmentTypes'),
  },
}
