import { factory, stateOptions } from '../utils'

export default {
  auto: '*',
  store: {
    state: {
      types: [
        {
          label: 'Rezervacinis',
          value: 'RESERVATION',
        },
        {
          label: 'Pagrindinis',
          value: 'MAIN',
        },
        {
          label: 'Perdavimas–priėmimas',
          value: 'TRANSFER_ACCEPTANCE',
        },
      ],
    },
    getters: {
      stepContractFactory: factory(() => ({
        docTemplateId: null,
        name: null,
        type: null,
        amendmentType: null,
      })),
      stepContractOptions: stateOptions(),
    },
  },
}
