import { getterOptions } from '../../utils'
import { prop } from 'rambda'

const PROJECT_STEP_BRANCHES = {
  2020: '2020',
  2022: '2022',
  '2022_V2': '2022_V2',
}

export const store = {
  state: {
    stepBranches: [
      {
        label: '2020 (sena)',
        value: PROJECT_STEP_BRANCHES[2020],
      },
      {
        label: '2022 (su rezervacine sut.)',
        value: PROJECT_STEP_BRANCHES[2022],
      },
      {
        label: '2022_V2 (apjungta sut.)',
        value: PROJECT_STEP_BRANCHES['2022_V2'],
      },
    ],
  },
  getters: {
    stepBranches: prop('stepBranches'),
    projectStepBranchOptions: getterOptions('stepBranches'),
  },
}
