<template>
  <div id="CalculatorReservable">
    <Loader
      v-if="!mainProject" />
    <Calculator
      v-if="mainProject"
      :project="mainProject"
      :value.sync="amount"
      @pvCapacity="setPvCapacity" />
    <Wrap v-if="mainProject && mainProject.status === 'ACTIVE'" class="form-wrap">
      <FormValidation class="form" @submit="submit">
        <div class="customAmount">
          <Field
            data-cy="capacity"
            label="Norima saulės elektrinės galia, kW"
            :max="capacityMax"
            :min="1"
            :step="0.1"
            type="number"
            :value.sync="pvCapacity" />
        </div>
        <Btn
          class="arrow"
          data-cy="confirmCapacity"
          :disabled="!pvCapacity || pvCapacity < 1 || pvCapacity > capacityMax"
          type="submit">
          Noriu rezervuoti {{ pvCapacity | commaDecimal(1) }} kW
        </Btn>
        <!--
        <Btn
          v-if="!hasCustomCapacity"
          class="inv no-border"
          key="reserve"
          @click="hasCustomCapacity = true">
          Noriu pats nustatyti elektrinės galią
        </Btn>
        -->
      </FormValidation>
    </Wrap>
  </div>
</template>

<script>
import { actions, getters } from 'views/utils'
import Calculator from 'components/Calculator'

export default {
  components: { Calculator },
  data() {
    return {
      amount: 200,
      reservationId: Number(this.$route.params.reservationId),
      // hasCustomCapacity: false,
      pvCapacity: 0,
      capacityMax: 5000,
    }
  },
  computed: {
    // project() {
    //   return this
    //     .queryOne('project', this.$route.params.id)
    // },
    ...getters('localStore', 'mainProject'),
  },
  methods: {
    setPvCapacity(pvCapacity) {
      this.pvCapacity = parseFloat(pvCapacity.toFixed(1), 10)
    },
    submit() {
      this.SET_CAPACITY_CACHE(this.pvCapacity)

      this.$router.push({
        name: this.authUser ? 'ReserveUser' : 'ReserveGuest',
        params: {
          amount: Math.round(this.pvCapacity * 100, 10),
          id: this.mainProject.id,
        },
      })
    },
    ...actions('SET_CAPACITY_CACHE'),
  },
  mounted() {
    if (!this.reservationId) {
      if (this.localStore('capacityCache')) this.setPvCapacity(this.localStore('capacityCache'))

      return
    }

    const reservation = this.queryOne('reservation', this.$route.params.reservationId)
    if (!reservation) return

    this.pvCapacity = reservation.capacity
  },
}
</script>

<style lang="scss">
#CalculatorReservable {
  background: $white-ter;
  padding-bottom: 5rem;

  .Hero {
    min-height: 30vh;
  }

  .form-wrap {
    margin-top: 2rem;
    max-width: 47rem;

    .customAmount {
      display: block;

      .Field-cont {
        text-align: center;

        input {
          text-align: center;
        }
      }
    }
  }
}
</style>
