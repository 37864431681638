<template>
  <div class="AlertBar">
    <transition-group appear name="slide-down">
      <Notice
        v-for="(alert, index) in alerts"
        :key="`${alert.text}-${index}`"
        :class="alert.theme"
        :isClosable="true"
        @close="close(alert.text)">
        {{ alert.text }}
        <template v-if="alert.button">
          <Btn class="danger ib xs" :to="alert.button.route" @click="close(alert.text)">
            {{ alert.button.text }}
          </Btn>
        </template>
      </Notice>
    </transition-group>
  </div>
</template>

<script>
import { actions, getters } from '../utils'

export default {
  computed: getters('alerts'),
  methods: {
    close(text) {
      this.CLOSE_ALERT({ text })
    },
    ...actions('CLOSE_ALERT'),
  },
}
</script>

<style lang="scss">
.AlertBar {
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 31;

  .Notice {
    margin: 0;

    .Btn {
      line-height: 1.25rem;
      padding: 0 1rem;
    }
  }
}
</style>
