<template>
  <Pic
    v-if="screenSizes.includes('lg')"
    alt=""
    class="Blob"
    :formats="['webp']"
    loading="lazy"
    :src="`../assets/landing/${name}.png`" />
</template>

<script>
import { getters } from '../../utils'

export default {
  props: {
    name: String,
  },
  computed: getters('screenSizes'),
}
</script>

<style lang="scss">
.Blob {
  display: none;
  pointer-events: none;
  position: absolute;
  @include lg {
    display: block;
  }
  @include xxl {
    > img {
      width: auto;
    }
  }
  @include xxxl {
    width: auto !important;
  }

  > img {
    width: 100%;
  }
}
</style>
