import { factory } from '../../utils'
import getDefaultCalculatorVariables from './utils/getDefaultCalculatorVariables'
import objectOwnerDetailsFactory from './utils/objectOwnerDetailsFactory'

export default factory(getters => ({
  name: null,
  status: 'ACTIVE',
  termDays: null,
  price: 950,
  priceBefore: null,
  maintenanceAnnual: 19,
  maintenanceAnnualBefore: null,

  capacity: null,
  capacityReserved: 0,
  capacityUsed: 0,
  constructionDate: null,

  locationFull: null,
  plotNumber: null,
  location: null,
  posX: null,
  posY: null,

  ...getDefaultCalculatorVariables(),

  description: '',

  isPublic: false,
  isListed: false,
  isMain: false,

  // legal data
  generatorGuaranteeYears: 12,
  generatorManufacturer: 'Suntech',
  inverterGuaranteeYears: 10,
  inverterManufacturer: 'Huawei',
  powerDecreasePercent: null,
  powerGuaranteeKwh: null,
  solarRadiation: null,
  inverterKw: null,

  objectOwnerDetails: objectOwnerDetailsFactory({}, getters)(),

  // inflation tracking
  inflationAdjustmentType: 'PROJECT',
  inflationAdjustments: {},

  // eso
  objectNo: null,
  objectOwner: null,

  // apis
  plantId: null,
  plantDataShareBreakpoints: null,

  // step process
  stepBranch: '2022_V2',
  canSkipReservation: false,

  invoicePrefix: null,
  payseraPrefix: null,
  paymentAccountId: null,

  // Directo integration
  directoEmail: null,
}))
