import { mapActions, mapGetters } from 'vuex'
import { _ } from '@feathersjs/commons'
import { parseObject } from '@/store/utils'

export { default as filterByQuery } from './query'

// Type Casters
export const castArray = array => Array.isArray(array) ? array : [array]

// Type Checkers
const { isObject, isObjectOrArray, merge, omit, pick } = _

export { isObject, isObjectOrArray, merge, omit, pick }

export const isNumeric = value => typeof value === 'number' ||
  (typeof value === 'string' && !isNaN(parseInt(value, 10)))

export const isError = value => isObject(value) &&
  (value instanceof Error || (value.code && value.name))

export const isJSONString = (value) => {
  try {
    JSON.parse(value)
    return true
  } catch (error) {
    return false
  }
}

// --------- Strings ---------
export const capitalize = string => string.charAt(0).toUpperCase() + string.slice(1)

export const camelCase = string => string
  .toLowerCase()
  .split(/[-_]/g)
  .map(capitalize)
  .join('')

export const randomElementId = (() => {
  const beginningDigits = /^\d+/

  // http://stackoverflow.com/questions/105034/how-to-create-a-guid-uuid-in-javascript
  return () => Math
    .random()
    .toString(36)
    .slice(2, 15)
    .replace(beginningDigits, '')
})()

// ---- Data Management ----
export const getters = (...array) => mapGetters([
  'authUser',
  'isAuth',
  'hasRole',
  'isLoaded',
  'query',
  'queryOne',
  // 'basePath',
  // 'staticUrl',
  ...array,
])

export const actions = (...array) => mapActions([
  // loaders
  'LOAD',
  'POST_LOAD',

  // error reporting
  'ERROR',

  // alerts
  // 'ALERT',
  // 'DANGER',
  'SUCCESS',

  // event binding
  'ON',

  // Analytics events
  // 'GEVENT',
  ...array,
])

/*
  Adds global components. Expects components in the same form as
  vm.components. For example: { OneComponent, SomeOtherComponent }.
*/
// export const registerComponents = components => Object
//   .keys(components)
//   .filter(componentName => !Vue.options.components[componentName])
//   .forEach(componentName => Vue.component(
//     componentName,
//     components[componentName],
//   ))

// --------- State ---------
export const stateList = state =>
  typeof state.list !== 'undefined'
    ? state.list
    : state

// --------- Math ---------
export const intoSum = (sum, number) => sum + number

// --------- Time ---------
export const startOfDay = (date) => {
  const momentFirst = new Date(date)
  momentFirst.setHours(0, 0, 0, 0)
  return momentFirst
}

export const startOfMonth = (date) => {
  const momentFirst = new Date(date)
  momentFirst.setDate(1)
  momentFirst.setHours(0, 0, 0, 0)
  return momentFirst
}

export const endOfMonth = (date) => {
  const momentLast = new Date(date)
  momentLast.setMonth(momentLast.getMonth() + 1, 1)
  momentLast.setHours(0, 0, 0, -1)
  return momentLast
}

export const startOfYear = (date) => {
  const momentFirst = new Date(date)
  momentFirst.setMonth(0, 1)
  momentFirst.setHours(0, 0, 0, 0)
  return momentFirst
}

// --------- Objects & Arrays ---------
export const clone = object => JSON.parse(JSON.stringify(object))
export const detach = object => parseObject(clone(object))
export const getUniqueArray = array => [...new Set(castArray(array))]
export const mergeAll = (sources) => {
  const [head, ...tail] = clone(sources)

  if (!head) return {}

  return merge(head, mergeAll(tail))
}

export const groupByKey = (key, items) => {
  const groups = {}

  items.forEach((items) => {
    if (!groups[items[key]]) {
      groups[items[key]] = [items]
      return
    }

    groups[items[key]].push(items)
  })

  return groups
}
