import { hashQuery, omitPagination, stateList } from './utils'
import Vue from 'vue'
import { castArray } from 'views/utils'
import modules from './modules'

const toIdMap = records => Object.fromEntries(records.map(record => [record.id, record]))

// TODO: simplify
// eslint-disable-next-line sonarjs/cognitive-complexity
function buildStoreMutations({
  isClearable, isFrozen, storeKey, storeSuffix, store,
}) {
  if (!storeSuffix) return {}

  const storeMutations = {
    ADD: (state, entries) => {
      const entriesValuesArray = castArray(entries)

      if (!entriesValuesArray.length) return

      const list = stateList(store.state)

      if (!isFrozen && entriesValuesArray.length === 1) {
        Vue.set(list, entriesValuesArray[0].id, {
          ...(list[entriesValuesArray[0].id] || {}),
          ...entriesValuesArray[0],
        })
        return
      }

      const appendValues = toIdMap(entriesValuesArray)
      const jointState = { ...list, ...appendValues }
      const updatedState = isFrozen ? Object.freeze(jointState) : jointState
      Vue.set(state[storeKey], 'list', updatedState)
    },

    REMOVE: (state, entries) => {
      const idsToStrip = castArray(entries).map(entry => entry.id)
      const list = stateList(store.state)

      if (!isFrozen) {
        idsToStrip.forEach((id) => {
          Vue.delete(list, id)
        })
        return
      }

      const remainingValues = toIdMap(Object
        .values(list)
        .filter(id => !idsToStrip.includes(id)))

      Vue.set(state[storeKey], 'list', Object.freeze({
        ...remainingValues,
      }))
    },

    ADD_PAGE_OBJECT: (state, { query, records, skip, total }) => {
      const pageStore = state[storeKey].pages
      const queryHash = hashQuery(omitPagination(query))
      const ids = records.map(record => record.id)
      const queryPages = pageStore[queryHash]

      if (!queryPages) {
        Vue.set(pageStore, queryHash, {
          total,
          positions: {
            [skip]: ids,
          },
        })
        return
      }

      if (queryPages.total !== total) {
        queryPages.total = total
      }

      if (queryPages.positions[skip]) {
        queryPages.positions[skip] = ids
      } else {
        Vue.set(queryPages.positions, skip, ids)
      }
    },
  }

  if (isClearable) {
    storeMutations.CLEAR = (state) => {
      Vue.set(state[storeKey], 'list', {})
    }
  }

  return Object.fromEntries(Object
    .entries(storeMutations)
    .map(([key, function_]) => [`${key}_${storeSuffix}`, function_]))
}

const mutations = modules
  .filter(storeModule => storeModule.auto.has('mutations'))
  .reduce((storeMutations, storeModule) => Object
    .assign(storeMutations, buildStoreMutations(storeModule)), {})

export default mutations
