import { apiBase as apiBaseDefault } from '@projectConfig'

export { appName, locale as defaultLocale, socialLogins } from '@projectConfig'

const {
  BASE_URL,
  NODE_ENV,
  VUE_APP_API_BASE,
  VUE_APP_API_ORIGIN,
  VUE_APP_WEB_URL,
} = process.env

const productionWebOrigin = 'saulesbendruomene.lt'

const clientOrigin = window.location.origin

export const basePath = (BASE_URL || '').slice(0, -1)

const clientUrl = `${clientOrigin}${basePath}`

export const webUrl = typeof VUE_APP_WEB_URL !== 'undefined'
  ? VUE_APP_WEB_URL
  : clientUrl

export const webOrigin = basePath
  ? webUrl.replace(basePath, '/')
  : webUrl

export const isDeployedProduction = clientOrigin.endsWith(productionWebOrigin)

export const isProduction = isDeployedProduction || NODE_ENV === 'production'

export const apiBase = VUE_APP_API_BASE || apiBaseDefault

export const apiOrigin = VUE_APP_API_ORIGIN || clientOrigin

export const apiUrl = `${apiOrigin}${apiBase}`

export const jwtName = 'amjwt'

export const bugsnagApiKey = 'ff9589504c6081bd5753e785220aba31'

export const facebookId = '1176773389175579'

// Google Analytics
export const analyticsId = 'UA-149326031-1'

export const recaptchaKey = isDeployedProduction
  ? '6Lf0TMcUAAAAAFXG4OyAp8Px1H9FpXriqsqRr1Ex'
  : '6LdrT8cUAAAAAPOQI9hUo4V4CVQ-j7tQG-qsxiVc'
