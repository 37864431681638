import { apiBase, jwtName } from 'config'
import { getterFinder, getterOptions } from '../utils'
import authentication from '@feathersjs/authentication-client'
import { checkRoute } from '@/router/authorization'
import client from '../client'
import router from '@/router'

const removeSymbols = string => string.replace(/[!<=>]/g, '')

client.configure(authentication({
  path: `${apiBase}/authentication`,
  storageKey: jwtName,
}))

export default {
  store: {
    state: {
      hasAuthChecked: false,
      hasAuthStarted: false,
      isLoggingOut: false,
      signupEmail: null,
      userId: null,
    },

    actions: {
      CHECK_LOGIN: async ({ commit, dispatch, state }) => {
        if (state.isLoggingOut || state.hasAuthStarted) return

        commit('SET_AUTH_STARTED')

        try {
          const payload = await client.reAuthenticate()
          dispatch('SET_PROFILE', payload.user)
        } catch (error) {
          if (error.name === 'NotAuthenticated') {
            return
          } else if (error.name === 'NotFound') {
            dispatch('LOGOUT')
            return
          }

          throw error
        } finally {
          commit('SET_AUTH_CHECKED')
        }
      },

      SIGNUP: async ({ dispatch }, user) =>
        dispatch('CREATE_USER', user),

      LOGIN: async ({ dispatch }, loginDetails) => {
        const { user } = await client.authenticate({
          strategy: 'local',
          ...loginDetails,
        })

        dispatch('SET_PROFILE', user)
      },

      LOGOUT: async ({ commit, dispatch }) => {
        commit('SET_LOGGING_OUT', true)

        await client.logout()

        delete localStorage[jwtName]

        client.emit('logout') // forces to restart user connection

        commit('SET_AUTH_USER_ID', null)
        commit('SET_LOGGING_OUT', false)

        // condition that ignores unit testing as it does not have a router
        if (router.app.$route) {
          checkRoute(router.app.$route, router.app.$route)
        }

        dispatch('CLEAR_STATE')
        dispatch('CLEAR_PAGINATIONS')
      },

      SET_AUTH_CHECKED({ commit }) {
        commit('SET_AUTH_CHECKED')
      },

      SET_AUTH_STARTED({ state, commit }) {
        if (!state.hasAuthStarted) {
          commit('SET_AUTH_STARTED')
        }
      },

      SET_PROFILE({ commit }, user) {
        commit('ADD_USER', user)

        if (user) {
          commit('SET_AUTH_USER_ID', user.id)
        }
      },

      SET_SIGNUP_EMAIL({ commit }, signupEmail) {
        commit('SET_SIGNUP_EMAIL', signupEmail)
      },
    },

    mutations: {
      SET_AUTH_STARTED: (state) => {
        state.hasAuthStarted = true
      },

      SET_AUTH_CHECKED: (state) => {
        state.hasAuthChecked = true
      },

      SET_LOGGING_OUT: (state, isLoggingOut) => {
        state.isLoggingOut = isLoggingOut
      },

      SET_AUTH_USER_ID: (state, userId) => {
        state.userId = userId
      },

      SET_SIGNUP_EMAIL: (state, signupEmail) => {
        state.signupEmail = signupEmail
      },
    },

    getters: {
      authRedirect: (_) => 'AdminUsers',
      authUser: ({ userId }, { query }) => userId
        ? query('user', userId)
        : null,
      authUserSettings: (_, { isAuth, authUser }) => isAuth
        ? authUser.settings
        : {},

      authHeaders: () => {
        const Authorization = localStorage[jwtName]
        return Authorization ? { Authorization } : {}
      },

      hasAuthChecked: state => state.hasAuthChecked,
      hasAuthToken: () => () => !!localStorage[jwtName],
      isAuth: (_, { authUser }) => !!authUser,
      signupEmail: state => state.signupEmail,

      roles: () => [
        { label: 'Dev', value: 'SUPERADMIN' },
        { label: 'Administratorius', value: 'ADMIN' },
        { label: 'Valdytojas', value: 'MANAGER' },
        { label: 'Pagalba', value: 'SUPPORT' },
        // { label: 'Moderatorius', value: 'MODERATOR' },
        // { label: 'Privilegijuotas', value: 'MEMBER' },
        { label: 'Vartotojas', value: 'USER' },
      ],
      roleKeys: (_, getters) => getters.roles.map(role => role.value),
      roleOptions: getterOptions('roles'),
      getRole: getterFinder('roles'),

      parseRole: (_, { roleKeys }) => (role) => {
        if (role === '*') return [...roleKeys]

        const cleanRole = removeSymbols(role)

        if (cleanRole.length === role.length) return [role]

        if (!roleKeys.includes(cleanRole)) {
          throw new Error(`Invalid role name ${cleanRole}`)
        }

        const roleIndex = roleKeys.indexOf(cleanRole)

        if (role.includes('>=')) {
          return roleKeys.slice(0, roleIndex + 1)
        } else if (role.includes('>')) {
          return roleKeys.slice(0, roleIndex)
        } else if (role.includes('<=')) {
          return roleKeys.slice(roleIndex)
        } else if (role.includes('<')) {
          return roleKeys.slice(roleIndex + 1)
        } else if (role.includes('!=')) {
          return roleKeys.filter((_, index) => index !== roleIndex)
        }

        return [cleanRole]
      },

      hasRole: (_, { isAuth, authUser, parseRole }) => (...roles) =>
        isAuth && roles
          .flatMap(parseRole)
          .some(role => parseRole(role).includes(authUser.role)),
    },
  },
}
