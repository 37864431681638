import { factory, stateOptions } from '../utils'

export default {
  auto: '*',
  store: {
    getters: {
      stepPaymentFactory: factory(() => ({
        amountFixed: null,
        amountMaintenancePercent: null,
        amountPercent: null,
        docTemplateId: null,
        expiresIn: null,
        name: null,
        type: null,
      })),
      stepPaymentOptions: stateOptions(),
    },
  },
}
