import { apiBase, apiOrigin, isProduction } from 'config'
import io from 'socket.io-client'
import socketio from '@feathersjs/socketio-client'

const config = {
  transports: ['websocket'],
  path: `${apiBase}/ws/`,
  secure: isProduction,
}

const socket = io(apiOrigin, config)

export default function() {
  this.configure(socketio(socket, {
    timeout: 30000,
  }))

  // forces to clear out user connection data
  this.on('logout', () => {
    socket.disconnect()
    socket.connect()
  })
}
