<template>
  <a
    :href="`mailto:${address}`"
    @click="trigger"
    @keydown.enter="trigger">
    <template v-if="!$slots.default">
      {{ address }}
    </template>
    <slot />
  </a>
</template>

<script>
import Track from '../../mixins/Track'

export default {
  mixins: [Track],
  props: {
    address: String,
  },
  methods: {
    trigger() {
      this.track('email', this.address)
    },
  },
}
</script>
