import { apiBase } from 'config'
import feathers from '@feathersjs/feathers'
import hooks from './hooks'
// // import rest from './rest'
import sockets from './sockets'

const client = feathers()
  // .configure(rest)
  .configure(sockets)
  .hooks(hooks)

client.getService = name => client
  .service(`${apiBase}/${name}`)

export default client
