import { curry, filter, map, pipe, pluck, uniq } from 'rambda'

export const removeIndex = curry((index, array) => array
  .filter((_, itemIndex) => index !== itemIndex))

export const dissocPath = curry((path, object) => {
  if (!path.length) return object

  const [key, ...rest] = Array.isArray(path)
    ? path
    : path.split('.')

  const objectNew = { ...object }

  if (!rest.length) {
    delete objectNew[key]
  } else {
    objectNew[key] = dissocPath(rest, object[key])
  }

  return objectNew
})

export const pluckUniq = pipe(pluck, uniq)

export const pluckTruthy = curry((key, array) => filter(Boolean, pluckUniq(key, array)))

export const pluckId = pluckTruthy

// export const pluckFlat = curry((key, array) =>
//   pluck(key, array).flat())

export const pluckIds = (ids, array) =>
  map(key => pluckId(key, array), ids)
