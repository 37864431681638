import { filterByQuery, merge } from 'views/utils'
import { hashQuery, omitPagination, stateList } from './utils'
import { path, prop } from 'rambda'
import modules from './modules'

// stub getters for each module
const storeModulesWithAutoGetters = Object
  .values(modules)
  .filter(storeModule => storeModule.auto.has('getters'))

const queryShallow = state => filterByQuery(stateList(state), state)
const queryOne = state => params => filterByQuery(stateList(state), state)(params, {
  isSingle: true,
})

storeModulesWithAutoGetters.forEach((storeModule) => {
  const { storeKey, storeSuffix } = storeModule

  merge(storeModule, {
    store: {
      getters: {
        [storeKey]: stateList,
        [`${storeKey}Suffix`]: state => state.storeSuffix,
      },
      state: {
        storeSuffix,
        pagination: {},
      },
    },
  })
})

const storeQueries = Object
  .fromEntries(storeModulesWithAutoGetters
    .map(storeModule => [storeModule.storeKey, {
      query: queryShallow,
      queryOne,
    }]))

const queryFactory = queryType => (state, getters) => (storeKey, params, options) =>
  storeQueries[storeKey][queryType](state[storeKey], getters)(params, options)

const getters = {
  getStoreModule: () => storeKey => modules
    .find(storeModule => storeModule.storeKey === storeKey),

  getPageObject: state => (storeKey, { $isForced, ...queryParams } = {}) => {
    return state[storeKey].pages[hashQuery(omitPagination(queryParams))]
  },

  getPageTotal: (_, { getPageObject }) => (storeKey, queryParams) =>
    prop('total', getPageObject(storeKey, queryParams)),

  getQueryPage: (_, { getPageObject }) => (storeKey, queryParams, skip) =>
    path(['positions', skip], getPageObject(storeKey, queryParams)),

  ...Object
    .fromEntries(['query', 'queryOne']
      .map(queryType => [queryType, queryFactory(queryType)])),
}

export default getters
