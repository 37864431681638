import { factory } from '../utils'

export default {
  auto: '*',

  store: {
    getters: {
      promoFactory: factory(() => ({
        projectId: null,
        prefix: null,
        quota: null,
        maintenancePerKw: null,
        yearsFreeMaintenance: null,
        pricePerKw: null,
        expiresAt: null,
        isShared: true,
      })),
    },
  },
}
